.accordion {
  @include reset-list;

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-right: 60px;
    cursor: pointer;
    transition: color $default-transition-settings, margin-bottom $default-transition-settings;

    &--green {
      color: $color-green;
    }

    @include mobile {
      padding-right: 0;
    }
  }

  &__title {
    margin: 0;
    font-family: $font-family-alt;
    font-size: 24px;
    line-height: 30px;
    color: $color-black;
    transition: color $default-transition-settings;
    font-weight: 400;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;

    &::after,
    &::before {
      @include pseudo;

      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 4px;
      background-color: $color-green;
      transition: transform $default-transition-settings, background $default-transition-settings;
    }

    &::before {
      width: 20px;
      height: 2px;
    }

    &::after {
      width: 2px;
      height: 20px;
    }

    @include mobile {
      display: none;
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s cubic-bezier(0, 1, 0, 1), padding-bottom 0.2s, transform 0s linear 0.3s;

    p {
      margin: 0;

      & + p {
        margin-top: 10px;
      }
    }
  }

  &__item {
    margin-bottom: 20px;
    padding: 40px;
    border-radius: 10px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    transition: box-shadow $default-transition-settings, border-color $default-transition-settings, padding $default-transition-settings;

    &:last-child {
      margin-bottom: 0;
    }

    @include mobile {
      padding: 25px;
    }
  }


  &__item--active {
    box-shadow: $shadow-main;
    border-color: $color-transparent;

    .accordion__header {
      margin-bottom: 25px;
    }

    .accordion__icon::after {
      transform: translate(-50%, -50%) rotate(90deg);
      background-color: $color-black;
    }

    .accordion__content {
      max-height: 9999px;
      transition: max-height 0.6s ease-in;
    }
  }

  &--faq {
    .accordion__item {
      padding: 0;
      padding-bottom: 30px;
      border: none;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
      border-radius: 0;

      &--active {
        box-shadow: none;
        padding-bottom: 50px;

        @include mobile {
          padding-bottom: 25px;
        }
      }
    }

    .accordion__header {
      margin-bottom: 0;
      padding: 20px 24% 20px 0;

      @include min-desktop {
        &:hover {
          .accordion__title {
            color: $color-green;
          }
        }
      }

      @include mobile {
        padding: 15px 40px 15px 0;
      }
    }

    .accordion__icon {
      @include mobile {
        display: initial;
      }
    }
  }
}
