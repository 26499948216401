.contacts-page {
  .items-list__checked-list {
    list-style: none;
  }

  .items-list__checked-item {
    position: relative;
    padding-left: 25px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 7px;
      width: 14px;
      height: 10px;
      background-image: url("../img/svg/icon-tick.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  .services {
    .subtitle {
      max-width: 840px;
    }

    .card__info {
      @include tablet {
        padding-top: 25px;
      }

      @include mobile {
        padding-top: 0;
      }
    }
  }

  .reasons,
  .services,
  .social {
    .subtitle {
      @include mobile {
        br {
          display: none;
        }
      }
    }
  }

  .check-form--bottom {
    .form {
      flex-direction: column;
      align-items: center;
    }

    p {
      margin-top: 30px;
      text-align: center;
    }

    p,
    a {
      color: $color-white;
    }

    .button {
      background-color: $color-transparent;
      border: 1px solid $color-green;
      border-radius: 0;
      margin-top: 32px;
      width: 200px;
    }

    .form__wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -20px;

      @include mobile {
        flex-direction: column;
        width: 100%;
      }
    }

    .form__inner {
      width: calc((100% - 20px) / 2);
      margin-bottom: 20px;

      @include tablet {
        margin-right: 20px;
      }

      @include mobile {
        margin-right: 0;
        width: 100%;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      input {
        width: 100%;
      }
    }
  }
}
