.trainer {
  .section__inner {
    flex-direction: row;

    @include mobile {
      flex-direction: column;
    }
  }

  .items-list__checked-list {
    margin: 0;
    padding: 0;
    max-width: 535px;
  }

  .subtitle,
  .title {
    text-align: left;

    @include mobile {
      display: none;
    }
  }

  &__info {
    max-width: 640px;
    margin-left: 95px;

    @include mobile {
      margin-top: 25px;
      margin-left: 0;
    }
  }

  &__name {
    margin: 0;
    margin-top: 25px;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: $color-black;
  }

  &__description {
    margin: 0;
    margin-top: 10px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  &__img {
    width: 235px;
    height: 235px;
    border-radius: 50%;
    background-color: $color-alice-blue;
    overflow: hidden;
    margin: 0 auto;

    img {
      transform: scale(-1, 1);
      margin-left: 20px;
      margin-top: 10px;
    }
  }
}
