.container {
  width: 100%;
  max-width: $container--1920;
  margin: 0 auto;

  @include container {
    max-width: $container--1280;
  }

  @include tablet {
    max-width: 100%;
    padding: 0 $padding-tablet;
  }

  @include mobile {
    padding: 0 $padding-mobile;
  }

  &--no-padding {
    @include mobile {
      padding: 0;
    }
  }
}
