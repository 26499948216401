.el_menu_btn {
  display: block;
  position: fixed;
  top: 15px;
  right: 15px;
  border: none;
  width: 45px;
  height: 45px;
  font-size: 21px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  outline: none;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.el_navigation_wrapper.el_main_menu_hide {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

.el_navigation_wrapper {
  @include hidden-scrollbar;
  z-index: 20;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.95);
  -webkit-transform: rotateY(-90deg) perspective(1000px);
  -moz-transform: rotateY(90deg) perspective(1000px);
  -ms-transform: rotateY(90deg) perspective(1000px);
  -o-transform: rotateY(90deg) perspective(1000px);
  transform: rotateY(-90deg) perspective(1000px);
  opacity: 0;
  transform-origin: left;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.el_navigation_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.el_navigation_logo {
  text-align: center;
  border-bottom: 1px solid #ffffff;
  background: rgba(0, 0, 0, 0.4);
}

.el_navigation_logo a {
  display: flex;
  justify-content: center;
}

.el_navigation_menu ul li a {
  display: block;
  padding: 15px;
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.el_navigation_menu ul li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: -20px;
  width: 0%;
  height: 100%;
  background: #ffffff;
  -webkit-transform: skewX(-30deg);
  -moz-transform: skewX(-30deg);
  -ms-transform: skewX(-30deg);
  -o-transform: skewX(-30deg);
  transform: skewX(-30deg);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  z-index: -1;
}

.el_navigation_menu ul li a::after {
  content: "";
  position: absolute;
  top: 0;
  right: -20px;
  width: 0%;
  height: 100%;
  background: #ffffff;
  -webkit-transform: skewX(-30deg);
  -moz-transform: skewX(-30deg);
  -ms-transform: skewX(-30deg);
  -o-transform: skewX(-30deg);
  transform: skewX(-30deg);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  z-index: -1;
}

.el_navigation_menu ul li:hover a {
  color: #18ba60;
}

.el_navigation_menu ul li:hover a::before,
.el_navigation_menu ul li:hover a::after {
  width: 100%;
}

.el_navigation_menu ul li a i {
  width: 40px;
  color: #18ba60;
}

.el_navigation_menu ul li a.active {
  color: #18ba60;
}

.el_navigation_menu ul li a.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: -20px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  -webkit-transform: skewX(-30deg);
  -moz-transform: skewX(-30deg);
  -ms-transform: skewX(-30deg);
  -o-transform: skewX(-30deg);
  transform: skewX(-30deg);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  z-index: -1;
}

.el_navigation_menu ul li a.active::after {
  content: "";
  position: absolute;
  top: 0;
  right: -20px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  transform: skewX(-30deg);
  transition: all 0.5s;
  z-index: -1;
}

.el_navigation_menu ul li .sub-menu li {
  background: white;
}

.el_navigation_menu ul li .sub-menu li a {
  color: #18ba60;
  position: relative;
  border-top: 2px solid #e1e1e1;
  transition: all 0.5s;
}

.el_navigation_menu ul li .sub-menu li a::after {
  content: "";
  z-index: 10;
  position: absolute;
  right: -13px;
  top: 50%;
  border: 12px solid transparent;
  border-right: 12px solid #18ba60;
  width: 20px;
  transform: skew(0deg) translate(0, -50%);
  height: 20px;
}

.el_navigation_menu ul li .sub-menu li a:hover::after {
  right: 0;
}

.el_navigation_menu ul li .sub-menu li a.active::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  border: 12px solid transparent;
  border-right: 12px solid #18ba60;
  width: 20px;
  transform: skew(0deg) translate(0, -50%);
  height: 20px;
}
