.promo {
  &__text {
    margin: 0;
    max-width: 668px;
    text-align: center;
  }

  &__slider {
    margin-top: 40px;
  }

  @include desktop {
    &__slider {
      margin-top: 40px;
    }
  }

  @include mobile {
    &__slider {
      margin-top: 25px;
    }
  }
}

.promo--direct {
  .subtitle {
    max-width: 600px;
  }

  @include mobile {
    padding-bottom: 25px;
  }

  .card {
    min-height: 325px;
    padding: 40px 30px;

    @include tablet {
      padding: 30px 10px;
    }

    @include mobile {
      min-height: 258px;
    }
  }

  .card__title {
    max-width: none;
    min-height: 33px;

    @include mobile {
      margin-top: 4px;
    }
  }

  .promo__text {
    margin-bottom: 0;
  }
}

.promo--target {
  @include mobile {
    padding-bottom: 24px;
  }

  .subtitle {
    max-width: 640px;
    margin-bottom: 0;

    @include mobile {
      min-width: 336px;
    }

    @include mobile-xs {
      min-width: 0;
    }
  }

  .card {
    padding: 40px 30px;

    @include mobile {
      padding-bottom: 30px;
    }
  }

  .card__title {
    max-width: none;

    @include mobile {
      margin-top: -4px;
    }
  }
}

.promo--context {
  .promo__text {
    @include mobile {
      margin-bottom: 26px;
    }
  }
  .promo__slider {
    margin-top: 0;
  }
}
