.slider-full {
  position: relative;
  margin: -20px 0 -30px; //для тени

  @include tablet {
    margin-top: -15px;
  }

  &__slide {
    height: auto;
  }

  &__controls-btn:focus,
  &__controls-btn:active {
    outline: none;
  }

  &__container {
    max-width: 100%;
    // overflow: visible;
    position: relative;
    padding: 20px 0 30px; //для тени

    @include tablet {
      padding-top: 25px;
    }
  }

  @include min-desktop {
    max-width: calc(100% + 600px);
    margin-left: -300px;
    padding-left: 300px;
    padding-right: 300px;
    box-sizing: content-box;
    width: 100%;
    overflow-x: hidden;

    @media (max-width: 1600px) {
      max-width: calc(100% + 300px);
      margin-left: -150px;
      padding-left: 150px;
      padding-right: 150px;
    }

    .swiper-button-disabled .slider-full__controls-btn--desktop {
      pointer-events: none;
      cursor: default;
    }
  }

  &__controls-btn {
    z-index: 10;
    margin-top: 0;
    position: absolute;
    top: 50%;
    transition: opacity $default-transition-settings, cursor 0s;

    svg {
      fill: $color-green;
    }

    &:first-of-type {
      left: 225px;
      transform: translate(-50%, -50%);


      @media (max-width: 1600px) {
        left: 100px;
      }


      @media (max-width: 1280px) {
        left: 160px;
      }

      @include tablet {
        left: 0;
      }
    }

    &:last-of-type {
      right: 225px;
      transform: translate(50%, -50%);

      @media (max-width: 1600px) {
        right: 100px;
      }


      @media (max-width: 1280px) {
        right: 160px;
      }

      @include tablet {
        right: 0;
      }
    }

    @include mobile {
      display: none;
    }
  }

  .js-slider-controls {
    &:focus,
    &:active {
      outline: none;
    }

    display: inline-block;

    & + .js-slider-controls {
      margin-left: 12px;
    }

    &[aria-disabled="false"] .slider-full__controls-btn {
      opacity: 1;
    }
  }

  &--coverflow {
    .slider-full__container {
      padding-top: 158px;

      @include tablet {
        padding-top: 0;
      }
    }

    .slider-full__controls-btn {
      top: 39%;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .swiper-pagination-bullet {
      margin: 0 8px 0 0 !important;
      display: block;
      width: 12px;
      height: 12px;
      background-color: $color-very-light-grey;

      &:last-child {
        margin-right: 0 !important;
      }

      &-active {
        background-color: $color-green;
      }
    }

    @include min-tablet {
      display: none;
    }
  }
}
