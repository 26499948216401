.reasons {
  .card--horizontal {
    min-height: auto;
    padding-right: 20px;

    @include tablet {
      padding-right: 10px;
      padding-left: 10px;
    }

    @include mobile {
      padding-right: 20px;
      padding-left: 20px;
    }

    .card__text {
      padding-right: 43px;

      @include tablet {
        padding-right: 33px;
      }

      @include mobile {
        padding-right: 43px;
      }
    }
  }
}

.reasons--target {
  @include mobile {
    padding-bottom: 28px;
  }

  .subtitle {
    max-width: 850px;
    margin-bottom: 45px;

    @include mobile {
      margin-bottom: 29px;
    }
  }

  .card {
    @include mobile {
      padding-bottom: 32px;
    }
  }

  .card__title {
    @include mobile {
      margin-top: 9px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .card--vertical .card__request p {
    max-width: 337px;
  }

  .card__request--wide {
    justify-content: flex-start;
  }

  .card__request-title {
    margin-top: 25px;
  }

  .card--vertical {
    min-height: 280px;
  }
}
