.quotes {
  background-color: $color-alice-blue;
  padding-bottom: 0;
  padding-top: 58px;

  &__inner {
    flex-direction: row;
  }

  &__img {
    align-self: flex-end;
    flex-shrink: 0;
    margin-right: 50px;

    @include tablet {
      width: 30%;
    }
  }

  &__content {
    padding-bottom: 57px;

    h2 {
      margin-bottom: 20px;
      text-align: left;
      font-size: 30px;
      line-height: 44px;
      font-weight: 500;
    }

    & > p {
      margin: 0;
      margin-bottom: 20px;

      &:last-of-type {
        padding-top: 14px;
        margin-bottom: 25px;
      }
    }

    .text-green {
      font-weight: 700;
      font-family: $font-family-primary-bold;
    }
  }

  &__footer {
    p {
      &:first-child {
        margin-bottom: 5px;
        font-family: $font-family-alt;
        font-size: 24px;
        line-height: 30px;
        color: $color-black;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  @include mobile {
    display: none;
  }
}

.quotes--direct {
  padding-top: 55px;

  .quotes__content {
    padding-right: 2px;
  }

  .quotes__content > p {
    margin-bottom: 25px;
  }

  .quotes__content > p:last-of-type {
    padding-top: 0;
  }
}
