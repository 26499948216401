.button {
  @include reset-btn;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  min-width: 270px;
  min-height: 50px;
  border-radius: 5px;
  background-color: $color-green;
  border: 1px solid $color-green;
  font-family: $font-family-primary-medium;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: $color-default-white;
  -webkit-tap-highlight-color: $color-transparent;
  transition: opacity $default-transition-settings,
    color $default-transition-settings,
    border-color $default-transition-settings;
  overflow: hidden;
  perspective: 1px;
  transform: translateZ(0);

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 70%;
    height: 100%;
    z-index: -1;
    transform: skewX(-30deg) translateZ(0) !important;
    transition: all 0.5s !important;
    background-color: $color-default-white !important;
  }

  &::before {
    left: -15px;
    transform: translateX(-100%) skewX(-30deg)  translateZ(0) !important;
  }

  &::after {
    right: -15px;
    transform: translateX(100%) skewX(-30deg)  translateZ(0) !important;
  }

  @include min-desktop {
    &:hover {
      color: $color-green !important;

      &::before,
      &::after {
        transform: translate(0, 0) skewX(-30deg)  translateZ(0) !important;
      }
    }
  }

  &--rounded {
    min-width: auto;
    padding: 12px 25px;
    border-radius: 100px;
    background-color: $color-transparent;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    font-family: $font-family-primary;
    font-weight: 400;
    color: $color-grey;
  }

  &--font-small {
    padding: 12px 15px;
    font-size: 16px;
  }

  &--outline {
    border-radius: 0;
    background-color: $color-transparent;

    &::before,
    &::after {
      background-color: $color-green;
    }

    @include min-desktop {
      &:hover {
        color: $color-default-white;
      }
    }
  }
}


.el_button {
	background: #18ba60;
  border: 1px solid transparent;
  padding: 8px 20px;
  font-size: 18px;
  color: #fff;
  overflow: hidden;
  font-weight: 400;
  position: relative;
  perspective: 1px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.el_button::before {
	content: "";
  position: absolute;
  top: 0;
  left: -15px;
  width: 0%;
  background: #18ba60;
  z-index: -1;
  height: 100%;
  transform: skewX(-30deg);
  transition: all 0.5s;
}

.el_button::after {
	content: "";
  position: absolute;
  top: 0;
  right: -15px;
  width: 0%;
  background: #18ba60;
  z-index: -1;
  height: 100%;
  transform: skewX(-30deg);
  transition: all 0.5s;
}

.el_button:hover {
  color: #ffffff;
}

.el_button:hover::before,
.el_button:hover::after {
	width: 70%;
}
