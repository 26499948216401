.faq {
  .accordion {
    margin-bottom: 20px;
  }

  @include tablet {
    .tabs__controls {
      @include hidden-scrollbar;

      justify-content: flex-start;
      max-width: 100%;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
    }

    .tabs__control-btn {
      flex-shrink: 0;
      scroll-snap-align: start;
    }
  }

  @include mobile {
    padding-top: 22px;

    .subtitle {
      padding: 0 15px;
    }

    &__inner {
      padding: 0;
    }

    .accordion {
      padding: 0 $padding-mobile;
    }
  }
}

.faq--direct {
  .subtitle {
    margin-bottom: 22px;

    @include mobile {
      margin-bottom: 12px;
    }
  }

  .accordion__content {
    max-width: 93%;

    @include mobile {
      max-width: 100%;
      font-size: 15px;
      line-height: 22px;
    }
  }

  .accordion__content p {
    padding-top: 4px;
  }
}

.faq--target {
  .subtitle {
    margin-bottom: 27px;

    @include mobile {
      margin-bottom: 11px;
    }
  }

  .accordion__content p {
    font-size: 15px;
    line-height: 22px;
  }
}
