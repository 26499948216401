.tabs {
  &__controls {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    @include mobile {
      min-height: 54px;
      align-items: center;
    }
  }

  &__control-btn {
    margin-right: 6px;
    margin-left: 6px;
    font-size: 16px;
    line-height: 24px;

    &:last-child {
      margin-right: 0;
    }

    &--active {
      background-color: $color-green;
      color: $color-default-white;
      border-color: $color-transparent;
    }
  }

  &__content-item {
    display: none;
    animation: fadeIn 0.3s ease;

    &--active {
      display: flex;
    }
  }

  &--slider-mobile {
    @include mobile {
      .tabs__content-item {
        display: flex;
      }
    }
  }

  &--selects {
    .select {
      display: none;
    }

    @include tablet {
      &-tablet {
        .select {
          display: block;
        }
      }
    }

    @include mobile {
      .select {
        display: block;
      }

      .tabs__controls {
        display: none;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: flex;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}
