.abilities {
  &__list {
    @include reset-list;

    counter-reset: list;


    li {
      display: flex;
      margin-bottom: 20px;

      @include mobile {
        flex-direction: column;
      }
    }
  }

  &__col {
    padding-top: 20px;
    width: 50%;

    &:first-child {
      padding-right: 20px;
      padding-top: 24px;
      width: 48.5%;

      @include mobile {
        width: 100%;
        padding-top: 0;
      }
    }

    h3 {
      counter-increment: list;
      position: relative;
      margin: 0;
      padding-left: 94px;
      font-family: $font-family-alt;
      font-size: 24px;
      font-weight: 400;
      color: $color-black;

      &::before {
        content: counter(list) ".";
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        font-family: $font-family-alt;
        font-size: 24px;
        font-weight: 700;
        color: $color-green;
      }

      @include mobile {
        padding-left: 35px;
      }
    }

    &-list {
      @include reset-list;

      li {
        position: relative;
        margin-bottom: 6px;
        padding-left: 32px;

        &:last-child {
          margin-bottom: 0;
        }

        svg {
          position: absolute;
          left: 0;
          top: 50%;
          fill: none;
          transform: translateY(-50%);
        }
      }
    }

    @include mobile {
      width: 100%;

      &:first-child {
        margin-bottom: 15px;
      }

      &-list {
        li {
          padding-left: 0;
          margin-bottom: 7px;

          svg {
            display: none;
          }
        }
      }
    }
  }

  .show-more__content {
    margin-bottom: 11px;
  }
}
