.articles {
  &__inner {
    .title {
      margin-left: -7px;
      margin-right: -7px;
      letter-spacing: -0.04em;
    }
  }

  @include mobile {
    &__inner {
      .title {
        margin-bottom: 10px;
      }

      & > p {
        margin-bottom: -8px;
      }
    }
  }
}

.articles--direct {
  .subtitle {
    max-width: 800px;
    margin-bottom: 12px;
  }

  .container .slider-mobile {
    @include mobile {
      padding-top: 5px;
    }
  }

  .card--article .card__img img {
    @include mobile {
      height: 55.5vw;
      object-fit: cover;
    }
  }
}
