.analytics-page {
  .video-block__title {
    @include mobile {
      // max-width: 280px;
      margin: 0 auto 19px;
    }
  }

  .video-block__content {
    width: 43%;

    @include tablet {
      width: 100%;
    }
  }

  .video-block__list li:not(:last-child) {
    margin-bottom: 15px;
  }

  .prices {
    .card--listed .card__list {
      margin-right: -10px;
    }

    .card--listed .card__list li:not(:last-child) {
      margin-bottom: 17px;
    }
  }

  .bigquery {
    .swiper-slide {
      display: flex;
    }

    @include min-desktop {
      .slider-full__container {
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 30px;
        padding-right: 30px;
        max-width: none;
      }
    }
  }
}
