.additional {
  &__inner {
    .title {
      margin-bottom: 60px;
    }
  }

  @include mobile {
    padding-bottom: 17px;

    &__inner {
      .title {
        margin-bottom: 19px;
      }

      .show-more__content {
        max-height: 596px;
      }
    }
  }
}

.additional--direct {
  padding-bottom: 75px;

  @include mobile {
    padding-top: 30px;
  }

  .additional__inner .title {
    margin-bottom: 18px;
  }

  .subtitle {
    max-width: 800px;
    margin-bottom: 46px;

    @include mobile {
      font-size: 15px;
      line-height: 23px;
      margin-bottom: 27px;
    }
  }

  .items-list__checked-list {
    padding-left: 56px;

    @include mobile {
      padding-left: 0;
    }
  }

  .items-list--column .items-list__item {
    padding-right: 1px;
    padding-bottom: 57px;
    margin-bottom: 41px;
  }

  .items-list--column .items-list__column {
    &:first-child {
      margin-right: 7.6%;
    }
  }

  .items-list__checked-item {
    padding-left: 30px;
    margin-bottom: 5px;

    @include mobile {
      margin-bottom: 7px;
    }
  }
}

.additional--target {
  .additional__inner .title {
    margin-bottom: 18px;
  }

  .subtitle {
    @include mobile {
      margin-bottom: 22px;
    }
  }

  .items-list__checked-list {
    @include mobile {
      padding-left: 0;
    }
  }

  .items-list__item:first-of-type {
    .items-list__column:first-child {
      width: 42%;
      margin-right: 7%;

      @include mobile {
        width: 100%;
      }
    }
  }

  .items-list--column .items-list__column:first-child {
    margin-right: 12.3%;
  }

  .items-list--column .items-list__item {
    margin-bottom: 44px;

    @include mobile {
      margin-bottom: 27px;
      padding-bottom: 33px;
    }
  }
}
