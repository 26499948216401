.stars {
  @include reset-list;
  display: flex;
  max-width: 100px;
  width: 100%;
  justify-content: space-between;

  &__item {
    color: $color-grey;

    & + & {
      margin-left: 5px;
    }
  }

  @for $i from 1 through 5 {
    &--#{$i} {
      .stars__item {
        color: $color-amber;
      }

      .stars__item:nth-child(n + #{$i + 1}) {
        color: $color-grey;
      }
    }
  }
}
