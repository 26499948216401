.reach-out {
  @include mobile {
    padding-bottom: 27px;
  }

  .card--horizontal {
    min-height: 150px;
    padding-bottom: 22px;

    @include mobile {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 17px;

      .card__column:nth-of-type(2) {
        margin-top: 7px;
      }
    }
  }

  .subtitle {
    max-width: none;

    @include mobile {
      margin-bottom: 27px;
    }
  }
}
