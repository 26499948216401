.bigquery {
  .title {
    .text-outline {
      &::before {
        top: -20px;
        background-image: url("../img/svg/text-outline2.svg");
        background-position: top 18px left;

        @include mobile {
          top: -17px;
          left: -15px;
          right: -18px;
        }
      }
    }
  }

  .subtitle {
    margin-bottom: 25px;
    max-width: 843px;
  }

  .flex-list {
    .card {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include mobile {
      &__item {
        margin-bottom: 15px;
      }
    }
  }

  &__slide-subtitle {
    max-width: 790px !important;
    margin-bottom: 16px !important;
  }

  &__decor-img {
    margin: 0 auto 12px;
    width: 140px;
    height: 65px;
    background-image: url("../img/bg/lines-bg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top left;
  }
}
