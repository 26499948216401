.lesson-info {
  display: flex;
  margin: 0 auto;
  padding: 0;
  justify-content: space-between;
  max-width: 363px;
  list-style: none;

  @include mobile {
    max-width: 262px;
  }

  &__item {
    display: flex;
    align-items: center;

    p {
      margin-left: 10px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: $color-white;

      @include mobile {
        margin-left: 5px;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
