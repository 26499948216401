.services-types {
  padding-top: 56px;

  @include mobile {
    padding-top: 0;
    padding-bottom: 30px;
  }

  .subtitle {
    max-width: 600px;

    @include mobile {
      margin-bottom: 27px;
    }
  }

  .card {
    padding: 40px 25px;
    min-height: 200px;
    padding-bottom: 43px;

    svg {
      margin-bottom: 0;

      @include mobile {
        margin-bottom: 20px;
      }
    }

    @include tablet {
      padding: 30px 15px;
    }

    @include mobile {
      padding: 30px 23px;
    }
  }

  .card__text {
    letter-spacing: 0;
  }

  .card__title {
    max-width: none;
    margin-bottom: 0;

    @include mobile {
      margin-bottom: 15px;
    }
  }

  .slider-full__controls-btn {
    top: 54.4%;
  }

  .slider-full__container {
    @include mobile {
      padding-bottom: 0;
    }
  }
}

.services-types__wrapper {
  @include mobile {
    display: flex;
    flex-direction: column;
  }
}

.services-types__type {
  @include mobile {
    position: relative;
    width: 100%;
    margin-bottom: 27px;

    &:not(:first-of-type) {
      padding-top: 36px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: 200vw;
        left: -50%;
        top: 0;
        background-color: rgba($color: $color-default-black, $alpha: 0.2);
      }
    }

    &:nth-of-type(2) {
      .card__title {
        min-height: 0;
      }
    }
  }
}

.services-types--target {
  @include mobile {
    padding-bottom: 34px;
  }

  .card {
    padding-left: 38px;
    padding-right: 41px;
    padding-bottom: 41px;

    @include mobile {
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 31px;
    }
  }

  .card--horizontal .card__title {
    margin-bottom: 16px;

    @include mobile {
      margin-top: -15px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }

  .card--horizontal .card__text {
    @include mobile {
      text-align: center;
    }
  }

  .slider-desk__pagination {
    margin-top: 22px;
  }

  .slider-full__controls-btn {
    top: 57.4%;
  }
}
