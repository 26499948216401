.automatation {
  &__image {
    flex-shrink: 0;
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    width: 40%;
    min-height: 272px;
    border-radius: 10px;
    box-shadow: $shadow-main;

    @include tablet {
      margin: 0 auto 20px;
      width: 100%;
      max-width: 330px;
      min-height: 200px;
    }

    @include mobile {
      min-height: 184px;
    }
  }

  .js-slider-full__wrapper {
    @include mobile {
      height: max-content;
      min-height: 701px;
    }

    .swiper-slide {
      @include mobile {
        height: max-content;
      }
    }
  }

  &__image--change-color {
    background-color: $color-default-black;
  }

  &__title {
    margin: 0;
    margin-bottom: 20px;
    font-family: $font-family-primary-medium;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $color-black;

    .text-green {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }

  &__text {
    margin: 0;

    p {
      margin: 0;
    }
  }

  &__tabs {
    width: 100%;
  }

  &__item {
    display: flex;
    padding: 30px 0 50px;
  }

  &--inner-slider {
    .slider-full {
      &__container {
        margin-left: -30px;
        padding-left: 30px;

        @include tablet {
          margin-left: 0;
          padding-left: 0;
          padding-top: 0;

          .select {
            margin-bottom: 0;
          }
        }
      }

      .tabs__controls {
        margin-left: -30px;

        @include container {
          margin-left: 0;
        }
      }
    }

    .js-slider-full__wrapper {
      .tabs__content-item {
        display: flex;

        @include tablet {
          display: none;

          &--active {
            display: flex;
          }
        }
      }

      & + .js-slider-mobile-pagination {
        display: flex;

        @include tablet {
          display: none;
        }
      }
    }

    .slider-mobile__wrapper {
      .tabs__content-item {
        display: none;

        &--active {
          display: flex;
        }

        @include tablet {
          display: flex;
        }
      }
    }

    .select {
      display: none;

      @include tablet {
        display: block;
      }
    }

    @include tablet {
      .slider-full__slide {
        .tabs__controls {
          display: none;
        }
      }

      .automatation__item {
        padding-bottom: 0;
      }
    }
  }

  @include container {
    .tabs__control-btn {
      padding: 12px 20px;
    }
  }

  @include tablet {
    .tabs__controls {
      @include hidden-scrollbar;

      justify-content: flex-start;
      max-width: 100%;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
    }

    .tabs__control-btn {
      flex-shrink: 0;
      scroll-snap-align: start;
    }

    .automatation__image {
      img {
        max-width: 85%;
      }
    }

    &__item {
      flex-direction: column;
    }
  }

  @include mobile {
    .tabs__controls {
      display: none;
    }

    &__inner {
      padding: 0;
    }

    &__item {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.automatation--direct {
  @include mobile {
    height: max-content;

    .automatation__inner,
    .slider-full__container,
    .slider-full {
      height: max-content;
    }
  }

  .title {
    margin-bottom: 0;

    @include mobile {
      margin-bottom: 12px;
    }
  }

  .automatation__item {
    padding-top: 15px;
  }

  .slider-full__controls-btn {
    top: 44%;

    @include tablet {
      top: 200px;
    }
  }

  .automatation__image {
    width: 41.2%;

    @include mobile {
      width: 100%;
      margin-top: 12px;
      min-height: 198px;
      margin-bottom: 10px;
    }
  }

  .automatation__title {
    margin-top: 12px;
  }

  .automatation__info {
    @include mobile {
      max-width: 93%;
    }
  }
}

.automatation--target {
  @include mobile {
    padding-top: 30px;
  }

  .title {
    margin-bottom: 0;

    &:nth-of-type(2) {
      display: none;
      margin-bottom: 8px;

      @include mobile {
        display: block;
      }
    }
  }

  .slider-full .tabs__controls {
    margin-bottom: 10px;
  }

  .automatation__image {
    width: 41%;

    @include mobile {
      width: 100%;
      margin-top: 12px;
      min-height: 198px;
      margin-bottom: 10px;
    }
  }

  .slider-full__controls-btn {
    top: 37%;

    @include tablet {
      top: 200px;
    }
  }

  .automatation__info {
    padding-top: 10px;
  }

  .automatation__item {
    @include mobile {
      padding-top: 16px;
    }
  }
}
