.video {
  position: relative;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    .video__play {
      opacity: 1;
    }
  }

  &.is-play {
    .video__wrapper iframe {
      opacity: 1;
      pointer-events: auto;
    }

    .video__play {
      display: none;
    }
  }

  &--fluid {
    height: 100%;

    .video__wrapper {
      height: 100%;
    }
  }

  &__img-wrapper {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    backface-visibility: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.video__wrapper {
  position: relative;
  width: 100%;
  padding-top: 61%;
  border-radius: 10px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity $default-transition-settings;
    border: 0;
  }

  @include tablet {
    padding-top: 62.115%;
  }
}

.video__play {
  @include reset-btn;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  height: 60px;
  border-radius: 7px;
  background-color: red;
  opacity: 0.6;
  transition: opacity $default-transition-settings;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-style: solid;
    border-width: 15px 0 15px 26px;
    border-color: $color-transparent $color-transparent $color-transparent $color-default-white;
  }

  @include mobile {
    width: 60px;
    height: 40px;

    &::before {
      border-width: 10px 0 10px 15px;
    }
  }
}
