.direct-scheme {
  padding-bottom: 19px;

  .subtitle {
    max-width: none;
  }

  @media (max-width: 1280px) {
    display: none;
  }

  @include mobile {
    display: none;
  }
}

.direct-scheme__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.direct-scheme__item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 310px;
  min-height: 90px;
  height: auto;
  padding: 20px 22px;
  margin-bottom: 60px;

  font-weight: 500;
  font-size: 16px;
  color: $color-dolphin;
  text-align: center;

  border-radius: 10px;
  border: 1px solid rgba($color: $color-default-black, $alpha: 0.2);

  transition: box-shadow $default-transition-settings, border-color $default-transition-settings;
  cursor: pointer;

  &:last-of-type {
    margin-left: auto;
  }

  &:hover {
    box-shadow: $shadow-main;
    border-color: transparent;
  }

  &--arrow-down-accented,
  &--accented {
    min-height: 100px;
    height: auto;

    font-size: 20px;
    line-height: 30px;
    color: $color-black;

    border: 2px solid $color-green;
  }

  &--arrow-down-accented::after,
  &--arrow-down::after,
  &--arrow-up::after,
  &--arrow-right::after {
    content: "";
    position: absolute;
    display: block;
    bottom: -52px;
    width: 16px;
    height: 41px;

    background: url("../img/svg/arrow.svg") center center no-repeat;
  }

  &--arrow-up::after {
    transform: rotate(180deg);
    bottom: auto;
    top: -52px;
  }

  &--arrow-right::after {
    width: 71px;
    height: 16px;

    background: url("../img/svg/arrow-dotted.svg") center center no-repeat;
    bottom: auto;
    right: -80px;
  }
}
