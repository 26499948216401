.automatation-promo {
  .card {
    min-height: 348px;
  }

  .card__title {
    min-height: auto;
    max-width: none;
  }
}
