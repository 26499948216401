.master-class {
  .items-list__checked-list {
    list-style: none;
    padding: 0;
  }

  .card__title,
  .subtitle {
    max-width: 100%;
  }

  .promo__text {
    @include mobile {
      max-width: 320px;

      br {
        display: none;
      }
    }
  }

  .video-block--target .title:first-of-type {
    display: block;
  }

  .items-list__checked-item {
    position: relative;
    padding-left: 25px;
    margin-bottom: 5px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 7px;
      width: 14px;
      height: 10px;
      background-image: url("../img/svg/icon-tick.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  .container {
    @include mobile {
      padding: 0 12px;
    }
  }

  .form {
    max-width: 100%;
  }

  .trophy {
    margin-top: 30px;
  }

  .button.el_button {
    width: 310px;

    @include mobile {
      width: 100%;
    }
  }

  .promo--target .card {
    padding: 30px 20px;
  }

  .videos {
    .subtitle {
      max-width: 727px;
    }
  }

  .trainer__info--mobile {
    display: none;

    @include mobile {
      display: block;
      margin-top: 0;
    }

    .title,
    .subtitle {
      @include mobile {
        display: block;
        text-align: center;
      }
    }
  }

  .reach-out .card--horizontal {
    padding-right: 25px;
  }

  .reporting {
    .mobile-break {
      display: none;
    }

    .container {
      @include mobile {
        padding: 0 11px;
      }
    }

    .subtitle {
      @include mobile {
        max-width: 330px;

        br {
          display: none;
        }

        .mobile-break {
          display: inline;
        }
      }
    }

    .card__title {
      display: none;
    }

    .card__text {
      text-align: left;

      @include mobile {
        text-align: center;

        br {
          display: none;
        }
      }
    }
  }

  .reporting--methods .card {
    @include mobile {
      padding: 18px;
    }
  }

  .reporting--methods .card__title {
    display: block;
  }

  .reporting--clients .title {
    max-width: 800px;

    @include mobile {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .container .slider-mobile {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -12px;
    margin-right: -12px;
  }

  .additional__inner .show-more__content {
    max-height: 376px;
  }

  .promo .subtitle {
    margin-bottom: 0;
  }

  .promo .card {
    width: 100%;
  }

  .promo .card__title {
    @include tablet {
      font-size: 20px;
      line-height: 28px;
    }

    @include mobile {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .promo .title,
  .articles .title,
  .reporting .title,
  .additional .title,
  .check-form .title {
    br {
      display: none;

      @include mobile {
        display: inline;
      }
    }
  }
}

