.reporting {
  .title {
    margin: 0 auto 20px;
    max-width: 500px;

    .text-outline {
      &::before {
        top: -14px;
        left: -11px;
        right: -15px;
        background-image: url("../img/svg/text-outline2.svg");
      }
    }
  }

  .subtitle {
    max-width: 900px;
  }

  .card {
    padding: 42px 40px;
    min-height: auto;
  }

  .flex-list__item {
    display: flex;
  }

  .btn-show-more {
    margin-top: 20px;

    @include mobile {
      display: none;
    }
  }
}

.reporting--direct-solve {
  @include mobile {
    padding-bottom: 20px;
  }

  .card--listed .card__list li {
    margin-bottom: 6px;
  }

  .title {
    max-width: none;

    @include mobile {
      margin-bottom: 15px;
    }
  }

  .subtitle {
    max-width: 970px;

    @include mobile {
      font-size: 15px;
      line-height: 23px;
      margin-bottom: 23px;
    }
  }

  .title--section:nth-child(2n) {
    display: none;
  }

  .card {
    border-color: $color-green;
    padding-right: 30px;

    @include tablet {
      padding: 30px 20px;
    }

    @include mobile {
      position: relative;
      padding-top: 39px;
      padding-bottom: 23px;
      margin-bottom: 10px;
      border-color: rgba($color: $color-default-black, $alpha: 0.2);
      width: 100%;
    }

    &:hover {
      border-color: transparent;
      box-shadow: $shadow-main;
    }
  }

  .card--listed .card__title {
    @include mobile {
      margin-left: 85px;
      margin-bottom: 34px;
    }
  }

  @include mobile {
    .card__column:first-child {
      position: absolute;
      left: 20px;
      top: 20px;
    }

    .title--section:nth-child(n) {
      display: none;
    }

    .title--section:nth-child(2n) {
      display: initial;
    }
  }

  .flex-list__item {
    @include mobile {
      width: 100%;
    }
  }
}

.reporting--direct-channels {
  @include mobile {
    padding-bottom: 25px;
  }

  .title {
    max-width: none;

    @include mobile {
      margin-bottom: 15px;
    }
  }

  .subtitle {
    max-width: 666px;

    @include mobile {
      margin-bottom: 27px;
    }
  }

  .card {
    width: 100%;
    padding-top: 39px;
    padding-bottom: 40px;

    @include tablet {
      padding: 30px 20px;
    }

    @include mobile {
      padding-bottom: 25px;
    }
  }

  .card--horizontal {
    .card__column:last-child {
      @include mobile {
        width: 100%;
      }
    }

    .card__column:first-child {
      @include mobile {
        margin-bottom: 18px;
      }
    }

    .card__title,
    .card__text {
      @include mobile {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }
  }

  .card__text {
    letter-spacing: 0;
  }

  .slider-mobile__pagination {
    margin-top: 25px;
  }
}

.reporting--direct-result {
  .title {
    max-width: 900px;
  }

  .subtitle {
    @include mobile {
      margin-bottom: 20px;
    }
  }

  .card.card--horizontal {
    padding-top: 37px;
    min-height: 176px;

    @include tablet {
      padding: 30px 15px;
    }
  }

  .card__title {
    @include mobile {
      margin-top: 6px;
    }
  }

  .card__title,
  .card--horizontal .card__text {
    @include mobile {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }

  .card--horizontal .card__text {
    @include mobile {
      max-width: 85%;
      margin: auto;
    }
  }
}

.reporting--clients {
  @include mobile {
    padding-bottom: 30px;
  }

  .title {
    max-width: 700px;

    @include mobile {
      font-size: 23px;
      line-height: 32px;
    }
  }

  .subtitle {
    max-width: 528px;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .card.card--horizontal {
    padding-top: 37px;
    min-height: 176px;

    @include tablet {
      padding: 30px 15px;
    }
  }

  .card__title {
    @include mobile {
      margin-top: 6px;
    }
  }

  .card--horizontal .card__title {
    @include mobile {
      text-align: center;
    }
  }

  .card__title,
  .card--horizontal .card__text {
    @include mobile {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }

  .card--horizontal .card__text {
    @include mobile {
      max-width: 85%;
      margin: auto;
    }
  }
}

.reporting--methods {
  @include mobile {
    padding-bottom: 30px;
  }

  .title {
    max-width: none;
  }

  .card {
    padding-top: 38px;
    padding-right: 70px;

    @include tablet {
      padding-left: 20px;
      padding-right: 50px;
    }

    @include mobile {
      padding: 28px 30px 30px;
    }
  }

  .subtitle {
    @include mobile {
      margin-bottom: 24px;
    }
  }

  .card__title {
    @include mobile {
      margin-top: 7px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
