.integration {
  .card {
    min-height: 274px;
  }

  .card__icon {
    margin-bottom: 20px !important;
  }

  .card__title {
    max-width: none;
    min-height: auto;
  }

  .show-more__content {
    max-height: 540px;
    padding-bottom: 40px;
  }

  .show-more--active {
    .show-more__btn {
      margin-top: 30px;
    }
  }

  @include mobile {
    .show-more__btn {
      margin-top: 20px;
    }
  }
}
