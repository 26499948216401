.stages {
  &__list {
    @include reset-list;

    display: flex;
    justify-content: space-between;
    background-image: url("../img/svg/stages-path.svg");
    background-repeat: no-repeat;
    background-size: 85%;
    background-position: left 114px top 140px;

    @include container {
      background-size: 80%;
    }

    @include container {
      background-size: 73%;
      background-position: left 152px top 178px;
    }

    @include tablet {
      background-position: left 103px top 178px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 195px;

    &:nth-child(even) {
      padding-top: 230px;
    }

    @include tablet {
      width: 150px;

      &:nth-child(even) {
        padding-top: 140px;
      }
    }
  }

  &__label {
    display: flex;
    align-items: flex-end;
    margin: 0;
    margin-bottom: 72px;
    min-height: 56px;
    font-family: $font-family-primary-medium;
    font-size: 20px;
    line-height: 28px;
    color: $color-black;
    text-align: center;
  }

  &__icon {
    position: relative;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 172px;
    height: 172px;
    border-radius: 50%;
    border: 3px solid $color-green;
    background-color: $color-default-white;
    transition: background $default-transition-settings;
    cursor: pointer;

    svg {
      fill: $color-green;
      transition: fill $default-transition-settings;
    }

    &::before {
      @include pseudo;

      top: -3px;
      left: 50%;
      width: 5px;
      height: 60px;
      transform: translate(-50%, -100%);
      background-image: url("../img/svg/stages-label-path.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    @include min-desktop {
      &:hover {
        background-color: $color-green;

        svg {
          fill: $color-default-white;
        }
      }
    }

    @include tablet {
      width: 120px;
      height: 120px;

      svg {
        width: 60px;
        height: 60px;
      }
    }
  }

  &__text {
    font-family: $font-family-primary-medium;
    font-size: 20px;
    line-height: 28px;
    color: rgba($color: $color-black, $alpha: 0.85);
    text-align: center;
  }
}
