.contest-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .button {
    width: 350px;

    @include mobile {
      max-width: 280px;
    }
  }

  .form__inner input {
    width: 350px;
    background-color: $color-transparent;
    border: 1px solid rgba($color-black, 0.1);
    border-radius: 5px;
    color: $color-black;

    @include mobile {
      max-width: 280px;
    }

    &::placeholder {
      color: rgba($color-black, 0.4);

      @include mobile {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &:focus {
      border-color: $color-green;
      background-color: $color-transparent;
      transition: border-color $default-transition-settings;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px $color-white inset;
      -webkit-text-fill-color: $color-black !important;
    }
  }

  .form__inner {
    margin-right: 0;
    margin-bottom: 15px;
  }

  p {
    max-width: 350px;
    margin-top: 15px;
  }

  p, a {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: rgba($color-black, 0.4);
  }

  a {
    text-decoration: underline;
    transition: color $default-transition-settings;

    &:hover {
      color: rgba($color-black, 0.6);
      transition: color $default-transition-settings;
    }
  }
}
