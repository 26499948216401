.workflow {
  &__inner {
    & > p {
      margin: 0 auto 40px;
      max-width: 668px;
      text-align: center;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .flex-list__item {
      width: calc((100% - 30px) / 2) !important;

      &:nth-child(3n) {
        margin-right: 30px !important;
        margin-top: 30px !important;
      }

      &:nth-child(2n) {
        margin-right: 0 !important;
      }
    }
  }

  @include mobile {
    padding-bottom: 33px;

    &__inner > p {
      margin-bottom: 26px;
    }
  }
}

.workflow--target {
  @include mobile {
    padding-bottom: 30px;
  }

  .card--vertical {
    min-height: 325px;
    padding-top: 37px;
    padding-bottom: 37px;

    @include mobile {
      padding-bottom: 31px;
    }
  }

  .card__title {
    max-width: 264px;
    min-height: 0;
    margin-top: -11px;
    margin-bottom: 15px;

    @include mobile {
      max-width: 200px;
      margin-top: 17px;
      margin-bottom: 12px;
    }
  }

  .card--vertical .card__icon {
    margin-bottom: 25px;

    @include mobile {
      margin-bottom: 0;
    }
  }

  .card--vertical .card__request h3 {
    max-width: 250px;
  }
}
