.analytics {
  .title {
    margin-bottom: 46px;
  }

  &__infographics {
    padding-top: 105px;
    padding-left: 37px;
    padding-right: 39px;
    background-image: url("../img/bg/analytics-bg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;

    @include container {
      background-size: 88%;
      background-position: top 40% center;
    }

    @include tablet {
      background-size: 89%;
      background-position: top 40% left 16%;
    }

    @media (max-width: 900px) {
      background-size: 84%;
      background-position: top 33% left 29%;
    }
  }

  &__list {
    @include reset-list;

    display: flex;
    justify-content: space-between;
  }

  &__icon {
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 130px;
    border-radius: 15px;
    border: 2px solid rgba($color: #000000, $alpha: 0.2);
    background-color: $color-default-white;
    transition: background $default-transition-settings;
    cursor: pointer;

    svg {
      fill: $color-green;
      transition: fill $default-transition-settings;
    }

    @include min-desktop {
      &:hover {
        background-color: $color-green;
        border-color: $color-transparent;

        svg {
          fill: $color-default-white;
        }
      }
    }

    @include container {
      width: 130px;
      height: 120px;
    }

    @include tablet {
      margin-bottom: 15px;
      width: 100px;
      height: 80px;

      svg {
        width: 60px;
        height: 60px;
      }
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 15px;
    max-width: 150px;
    font-family: $font-family-alt;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    color: $color-black;

    @include tablet {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__item {
    ul {
      @include reset-list;

      font-family: $font-family-primary;
      font-size: 17px;
      line-height: 24px;
      color: $color-dim-gray;
      max-width: 150px;

      li {
        position: relative;
        margin-bottom: 5px;
        padding-left: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          @include pseudo;

          left: 0;
          top: 8px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $color-green;
        }
      }
    }
  }

  @include mobile {
    display: none;
  }
}
