.services {
  border-bottom: none;

  &__list {
    @include reset-list;

    display: flex;
    flex-direction: column;

    li {
      margin-bottom: 80px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include mobile {
      flex-direction: row;

      li {
        margin-bottom: 0;
      }
    }
  }

  @include mobile {
    border-bottom: 1px solid rgba($color: $color-default-black, $alpha: 0.2);
  }
}

.services--direct {
  .subtitle {
    max-width: 800px;

    @include mobile {
      margin-bottom: 28px;
    }
  }

  .card--full-width .card__info {
    padding-top: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .card--full-width .card__icon {
    @include mobile {
      width: 100%;
      height: 47vw;
      margin-bottom: 25px;
    }
  }

  .card--full-width {
    @include mobile {
      padding: 0;
    }
  }
}

.services--additional {
  @include mobile {
    padding-top: 30px;
  }

  .subtitle {
    max-width: none;

    @include mobile {
      max-width: 95%;
      margin-bottom: 27px;
    }
  }

  .card--full-width .card__info {
    padding-top: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .card--full-width .card__icon {
    @include mobile {
      width: 100%;
      height: 47vw;
      margin-bottom: 25px;
    }
  }

  .card--full-width {
    @include mobile {
      padding: 0;
    }
  }
}

.services--target {
  border-bottom: 1px solid rgba($color: $color-default-black, $alpha: 0.2);

  @include mobile {
    padding-bottom: 26px;
  }

  .subtitle {
    @include mobile {
      margin-bottom: 28px;
    }
  }

  .card--full-width .card__info {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .card--full-width .card__icon {
    @include mobile {
      width: 100%;
      height: 47vw;
      margin-bottom: 25px;
    }
  }

  .card--full-width {
    @include mobile {
      padding: 0;
    }
  }

  .slider-mobile__pagination {
    @include mobile {
      margin-top: 30px;
      border-top: 1px solid transparent;
    }
  }

  .swiper-wrapper {
    @include mobile {
      height: min-content;
      min-height: min-content;
    }
  }

  .card--full-width .card__info p {
    @include mobile {
      max-width: 96%;
    }
  }
}

.services--context {
  padding-top: 75px;

  @include tablet {
    padding-top: 34px;
    padding-bottom: 28px;
  }
}

.services--context .card__info {
  align-self: center;
}
