.address {
  .subtitle {
    max-width: 760px;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    @include tablet {
      flex-direction: column;
    }
  }

  &__map {
    max-width: 730px;
    min-width: calc(100% - 380px);
    height: 496px;
    display: block;
    border: 0;
    border-radius: 10px;
    margin-right: 30px;

    @include tablet {
      max-width: 100%;
      min-width: 100%;
      height: 390px;
      margin-right: 0;
    }
  }

  .flex-list {
    margin-bottom: -20px;

    @include tablet {
      margin-top: 25px;
    }
  }

  .flex-list__item {
    max-width: 350px;
    margin-bottom: 20px;

    @include tablet {
      max-width: 100%;
      margin-bottom: 15px;
    }
  }

  .card {
    width: 100%;
    min-height: 136px;
    padding: 30px;
  }

  .card__text {
    color: $color-dim-gray;
  }

  .card--horizontal {
    @include mobile {
      flex-direction: row;

      .card__column:first-child {
        margin-right: 20px;
        align-self: flex-start;
      }
    }
  }
}
