.stages {
  padding-bottom: 43px;

  .items-list--column .items-list__item {
    padding: 0;
    margin-bottom: 0;

    &::before {
      content: none;
    }

    &:first-child {
      .items-list__checked-list {
        margin-top: 24px;
        height: 128px;

        @media (max-width: 1279px) {
          height: 139px;
        }
      }
    }

    &:last-child {
      .items-list__checked-list {
        margin-bottom: 22px;
        height: 144px;

        @media (max-width: 1279px) {
          height: 161px;
        }
      }
    }
  }

  .items-list--column .items-list__column:first-child {
    width: 490px;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items:center;

    h3 {
      max-width: 280px;
      font-weight: 700;
      text-align: center;
      color: $color-default-white;
    }
  }


  .items-list__checked-list {
    margin: 36px 0;
    height: 168px;

    @media (max-width: 1279px) {
      margin: 24px 0;
      height: 191px;
    }
  }
}
