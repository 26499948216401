.intensiv {
  .items-list__checked-list {
    list-style: none;
    padding: 0;
  }

  .items-list__checked-item {
    position: relative;
    padding-left: 25px;
    margin-bottom: 5px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 7px;
      width: 14px;
      height: 10px;
      background-image: url("../img/svg/icon-tick.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  .video-block--target .title:first-of-type {
    display: block;
  }

  .video-block--target {
    @include mobile {
      padding-bottom: 15px;
    }
  }

  .trophy {
    margin-top: 30px;
    grid-template-columns: 160px auto;

    .b {
      font-weight: 700;
    }

    .trophy__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .reporting--methods {
    .card__title {
      @include mobile {
        min-height: auto;
      }
    }

    .card {
      padding-left: 40px;
      padding-right: 40px;

      @include mobile {
        padding-left: 14px;
        padding-right: 14px;
      }
    }

    .card--vertical .card__icon {
      line-height: 24px;
    }

    .subtitle br {
      display: none;

      @include mobile {
        display: inline;
      }
    }
  }

  .promo--target .subtitle {
    max-width: 727px;

    @include mobile {
      min-width: auto;
    }
  }

  .questions .subtitle {
    max-width: 100%;
  }

  .reporting .subtitle {
    max-width: 740px;
  }

  .trainer__info--mobile {
    display: none;

    @include mobile {
      display: block;
      margin-top: 0;
    }

    .title,
    .subtitle {
      @include mobile {
        display: block;
        text-align: center;
      }
    }
  }

  .additional {
    .title br {
      display: none;

      @include mobile {
        display: inline;
      }
    }

    .subtitle {
      @include mobile {
        font-size: 15px;
        line-height: 23px;
      }
    }

    @include mobile {
      padding-bottom: 30px;
    }
  }

  .form {
    max-width: 100%;
  }

  .reach-out {

    .card__title {
      @include tablet {
        font-size: 20px;
        line-height: 28px;
      }

      @include mobile {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  .tasks {
    .title br {
      display: none;

      @include mobile {
        display: inline;
      }
    }
  }

  .button.el_button {
    width: 310px;

    @include mobile {
      width: 100%;
    }
  }

  .check-form {
    .title br {
      display: none;

      @include mobile {
        display: inline;
      }
    }

    .subtitle {
      max-width: 100%;
    }
  }

  .registration {
    .subtitle br {
      display: none;

      @include mobile {
        display: inline;
      }
    }

    .button {
      margin-top: auto;
      margin-bottom: 0;
    }

    .card {
      width: 100%;

      @include mobile {
        padding: 40px 25px;
      }

      .title {
        font-weight: 700;
      }

      h4 {
        margin: 0;
        margin-bottom: 12px;
      }
    }

    .card__title {
      min-height: auto;
      margin-bottom: 8px;
    }

    .flex-list__item:first-child {
      .button {
        background-color: $color-green;
        color: $color-white;

        &:hover {
          color: $color-green !important;
        }

        &::after,
        &::before {
          background-color: $color-white !important;
        }
      }
    }
  }

  .reporting--clients {
    .reporting__slider {
      margin-top: 63px;

      @include mobile {
        margin-top: 0;
      }
    }

    .subtitle {
      position: relative;

      &::after {
        position: absolute;
        content: "";
        width: 243px;
        height: 90px;
        right: -10px;
        bottom: -100px;
        background-image: url("../img/svg/dashed-line.svg");

        @include mobile {
          display: none;
        }
      }

      &::before {
        position: absolute;
        content: "";
        width: 243px;
        height: 90px;
        left: -10px;
        bottom: -100px;
        background-image: url("../img/svg/dashed-line.svg");
        transform: scale(-1, 1);

        @include mobile {
          display: none;
        }
      }
    }
  }
}
