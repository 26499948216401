.title {
  margin: 0 0 20px 0;
  color: $color-black;
  text-align: center;
  font-family: $font-family-primary-medium;

  &--section {
    font-size: 36px;
    line-height: 44px;
    font-weight: 500;
  }

  &--size {
    font-size: 24px;
    line-height: 32px;
  }

  &--mobile {
    display: none;
  }

  @include tablet {
    &--desktop {
      display: none;
    }

    &--mobile {
      display: block;
    }
  }

  @include mobile {
    margin-bottom: 15px;

    &--section {
      font-size: 30px;
      line-height: 40px;
    }
  }

  @include mobile-xs {
    &--section {
      font-size: 27px;
    }
  }

  &--white {
    color: $color-default-white;
  }
}

.subtitle {
  margin: 0 auto 40px;
  max-width: 727px;
  text-align: center;

  &--white {
    color: $color-default-white;
  }
}
