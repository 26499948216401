.section {
  padding: 80px 0;
  border-bottom: 1px solid rgba($color: $color-default-black, $alpha: 0.2);

  &__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &--centered {
    .section__inner {
      align-items: center;
    }
  }

  @include mobile {
    padding: 35px 0;
  }
}
