.el_banner_wrapper {
  position: static;
  height: 100vh;
  min-height: 900px;

  @include mobile {
    min-height: 568px;
  }
}

.el_banner_caption {
  position: relative;
  z-index: 1;

  @include tablet {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobile {
    padding-right: 0;
    padding-left: 0;
  }
}

.lesson-info {
  margin-bottom: 64px;

  @include mobile {
    margin-bottom: 32px;
  }
}

.lesson-info__item p {
  margin-bottom: 0;
}

.el_banner_caption > p {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 55px;

  @include mobile {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px;
  }
}


#particles-js {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;

  @include mobile {
    justify-content: flex-start;
  }
}

.el_banner_wrapper .container {
  position: static;
  transform: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include mobile {
    justify-content: flex-start;
    margin-top: 120px;
  }

  @include mobile-xs {
    margin-top: 60px;
  }
}

.particles-js-canvas-el {
  position: absolute;
  top: 0;
  left: 0;
}

.el_banner_caption {
  .form__inner {
    margin-right: 20px;

    @include tablet {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  .check-form__form {
    padding-left: 23px;
    margin-bottom: 20px;

    @include mobile {
      padding-left: 0;
      margin-bottom: 15px;
    }
  }

  .check-form__inner > p:first-of-type {
    font-size: 12px;
    line-height: 18px;
  }

  .typing_text {
    margin-bottom: 28px;

    @include mobile {
      margin-bottom: 23px;
    }
  }

  #typed-strings {
    display: none;
  }

  .check-form {
    padding: 0;
  }

  .check-form--no-bg input {
    @include desktop {
      width: 255px;
    }

    @include tablet {
      margin-left: auto;
      margin-right: auto;
    }

    @include mobile {
      width: 100%;
      font-size: 17px;
      line-height: 24px;
    }
  }

  .check-form__inner {
    @include mobile {
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .el_button {
    @include mobile-xs {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
