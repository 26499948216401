.tools {
  border-bottom: none;

  .subtitle {
    margin-bottom: 67px;
    max-width: 880px;
  }

  .show-more__content {
    max-height: 479px;
  }

  .items-list--column .items-list__item {
    margin-bottom: 42px;

    @include mobile {
      margin-bottom: 15px;
    }
  }

  .items-list--column .items-list__column:first-child {
    width: 39%;

    @include mobile {
      width: 100%;
    }
  }

  .items-list__item {
    padding-bottom: 58px;

    @include mobile {
      padding-bottom: 23px;
    }
  }
}
