.effects {
  .card {
    min-height: 208px;
  }

  .flex-list__item {
    display: flex;
    justify-content: center;

    &--column {
      flex-direction: column;
    }
  }
}
