.check-form {
  padding: 101px 0;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
    url("../img/bg/check-bg.jpg");
  // background: url("../img/bg/check-bg.jpg");
  background-size: cover;
  background-attachment: fixed;

  &--no-bg {
    background: none;

    input {
      width: 310px;
    }
  }

  &--bottom {
    background-image: url("../img/bg/check-bg-bottom.jpg");
  }

  &__inner {
    .title {
      color: $color-default-white;
    }

    & > p {
      margin: 0 auto;
      max-width: 727px;
      color: rgba($color: $color-default-white, $alpha: 0.65);
      text-align: center;

      &:first-of-type {
        margin-bottom: 40px;

        @include mobile {
          margin-bottom: 25px;
        }
      }

      a {
        color: rgba($color: $color-default-white, $alpha: 0.65);
        text-decoration: underline;
        text-underline-offset: 2px;
        transition: opacity $default-transition-settings;

        @include min-desktop {
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    @include mobile {
      padding: 0 15px;
    }
  }

  &__form {
    margin: 0 auto 25px;
    max-width: 745px;
  }

  @include tablet {
    padding: 35px 0;
  }

  @include mobile {
    padding-bottom: 35px;
    background-attachment: initial;
  }
}

.check-form--order,
.check-form--target {
  @include mobile {
    padding-bottom: 30px;
  }

  .form {
    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .form input,
  .button.el_button {
    width: 235px;
    min-width: 0;
  }

  .form__inner,
  .form input,
  .button.el_button {
    @include mobile {
      width: 100%;
    }
  }

  .check-form__inner > p:last-of-type {
    @include mobile {
      font-size: 15px;
      line-height: 24px;
    }
  }
}

.check-form--order {
  padding-top: 110px;
  padding-bottom: 113px;

  @include mobile {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
