.icon-staircase {
  &__row {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
