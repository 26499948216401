.check-list {
  .title {
    margin-bottom: 60px;
  }

  .items-list--column .items-list__item {
    margin-bottom: 42px;
  }

  .items-list--column .items-list__column:first-child {
    width: 39%;

    @include mobile {
      width: 100%;
    }
  }

  .items-list__item {
    padding-bottom: 58px;
  }

  .show-more__content {
    max-height: 548px;
  }
}
