.form {
  display: flex;

  &__inner {
    position: relative;
    margin-right: 20px;

    &.invalid {
      box-shadow: $shadow-invalid;

      .form__error {
        opacity: 1;
      }
    }

    &.valid {
      box-shadow: $shadow-valid;
    }

    @include tablet {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__error {
    position: absolute;
    display: block;
    left: 50%;
    bottom: -22px;
    font-size: 12px;
    color: red;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity $default-transition-settings;
  }

  input {
    padding: 12px 20px 13px;
    min-height: 50px;
    border: none;
    color: $color-default-white;
    background-color: rgba($color: #000000, $alpha: 0.5);
    outline: none;
    transition: opacity $default-transition-settings;
    cursor: pointer;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px rgba($color: #000000, $alpha: 0.9) inset;
      -webkit-text-fill-color: white !important;
    }

    @include min-desktop {
      &:hover {
        opacity: 0.7;
      }
    }

    @include placeholder {
      color: $color-default-white;
    }
  }

  @include tablet {
    flex-direction: column;
    max-width: none;
    width: 100%;

    &__inner {
      input {
        width: 100%;
      }
    }

    .button {
      align-self: center;
    }
  }

  @include mobile {
    .button {
      align-self: initial;
    }
  }

  &--grid {
    flex-direction: column;

    .button {
      min-width: 200px;
      align-self: center;
    }

    .form__row {
      display: flex;
      margin-bottom: 30px;

      @include mobile {
        flex-direction: column;
        margin-bottom: 0;
      }
    }

    .form__inner {
      flex-grow: 1;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }

      input {
        width: 100%;
      }

      @include mobile {
        margin-right: 0;
      }
    }

    & > p {
      margin: 0 auto 49px;
      max-width: 727px;
      color: $color-default-white;
      text-align: center;

      &:first-of-type {
        margin-bottom: 40px;

        @include mobile {
          margin-bottom: 25px;
        }
      }

      a {
        color: $color-default-white;
        text-decoration: underline;
        text-underline-offset: 2px;
        transition: opacity $default-transition-settings;

        @include min-desktop {
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
