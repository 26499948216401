.video-block {
  padding-bottom: 86px;

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include tablet {
      flex-direction: column;
    }
  }

  &__title {
    &.title {
      text-align: left;
      padding-right: 68px;
    }

    &--no-padding {
      &.title {
        padding-right: 0;
      }
    }
  }

  &__content {
    margin-right: 20px;
    width: 48%;

    @include tablet {
      width: 100%;
      margin-right: 0;
      margin-bottom: 25px;
    }
  }

  &__video {
    flex-grow: 1;
    max-width: 554px;

    a {
      width: 100%;
    }

    @include tablet {
      max-width: 100%;
    }
  }

  &__text {
    margin: 0;
    margin-bottom: 25px;

    @include tablet {
      text-align: center;
    }
  }

  &__list {
    @include reset-list;
    color: $color-black;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        @include pseudo;

        left: 0;
        top: 8px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $color-green;
      }
    }
  }

  .video__wrapper {
    padding-top: 64%;
  }

  @include tablet {
    &__title {
      text-align: center;
    }
  }

  @include tablet {
    padding-bottom: 48px;

    &__title.title {
      padding-right: 0;
      text-align: center;
    }
  }
}

.video-block--direct {
  padding-bottom: 56px;

  @include tablet {
    padding-bottom: 13px;
  }

  .video-block__text {
    margin-bottom: 13px;

    @include tablet {
      margin-bottom: 25px;
      text-align: left;
    }
  }

  .video-block__title {
    @include tablet {
      margin-bottom: 28px;
    }
  }

  .video-block__content {
    width: 44%;

    @include tablet {
      width: 100%;
      margin-bottom: 9px;
    }
  }

  .video-block__list li {
    margin-bottom: 16px;
  }

  .video__img-wrapper img {
    min-height: 320px;

    @include tablet {
      min-height: 191px;
    }
  }

  .video__play {
    top: 45%;
  }
}

.video-block--target {
  padding-bottom: 66px;

  @include mobile {
    padding-bottom: 35px;
  }

  .title {
    padding: 0;

    &:nth-of-type(2) {
      display: none;

      @include mobile {
        display: block;
        text-align: center;
      }
    }

    &:first-of-type {
      @include mobile {
        display: none;
      }
    }
  }

  .video-block__text {
    margin-bottom: 13px;

    @include mobile {
      text-align: left;
      margin-bottom: 26px;
    }
  }

  .video-block__list li {
    margin-bottom: 16px;
  }

  .video__img-wrapper img {
    min-height: auto;
    object-fit: contain;
  }

  .video__play {
    top: 45%;
  }

  .video-block__content {
    @include mobile {
      margin-bottom: 9px;
    }
  }
}

.video-block--context {
  padding-top: 87px;

  @include mobile {
    padding-bottom: 10px;
  }

  .video-block__content {
    width: 46%;

    @include tablet {
      width: 100%;
    }
  }

  .video-block__text {
    margin-bottom: 13px;

    @include mobile {
      text-align: left;
      margin-bottom: 28px;
    }
  }

  li {
    margin-bottom: 17px;
    letter-spacing: -0.03em;

    @include mobile {
      padding-right: 10px;
    }
  }

  .video__play {
    top: 43%;
  }
}
