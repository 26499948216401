.integration-page {
  .video-block {
    .text-underline {
      &::before {
        width: 100%;
        height: 45%;
        left: 0;
        bottom: -28%;
      }

      @media (max-width: 375px) {
        &::before {
          bottom: -10%;
        }
      }
    }
  }

  .problems {
    .card__title {
      max-width: none;
    }
  }

  .automatation {
    .tabs__control-btn {
      font-size: 14px;
    }
  }

  .integration {
    .card {
      min-height: 346px;
      padding: 30px 39px;

      &--callback {
        padding-top: 55px;
        padding-left: 59px;

        .card__text {
          max-width: 68%;
        }

        @include tablet {
          padding-left: 30px;
          padding-top: 40px;

          .card__title {
            max-width: 43%;
          }

          .card__text {
            max-width: 50%;
          }
        }
      }
    }
  }

  .integration-infographics {
    .subtitle {
      max-width: 830px;
    }

    @include mobile {
      display: none;
    }
  }

  .reporting {
    .card {
      @include mobile {
        padding: 42px 15px;
      }
    }
  }

  .purposes {
    .card--small {
      min-height: 262px;
    }
  }
}
