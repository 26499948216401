$flex-list-gap: 30px;

.flex-list {
  @include reset-list;
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    justify-content: center;
  }

  @for $i from 2 through 6 {
    &--#{$i} .flex-list__item {
      @include min-tablet {
        &:not(.flex-list__item--wide) {
          width: calc((100% - #{$flex-list-gap*($i - 1)}) / #{$i});
        }

        &:not(:nth-of-type(#{$i}n)):not(.flex-list__item--wide) {
          margin-right: $flex-list-gap;
        }

        &:nth-of-type(n + #{$i + 1}) {
          margin-top: 30px;
        }
      }
    }
  }

  @for $i from 2 through 6 {
    &--#{$i}.flex-list--squares {
      .flex-list__item {
        min-height: ($container - 30px * ($i - 1)) / $i;

        @include min-desktop {
          &:nth-of-type(n + #{$i + 1}) {
            margin-top: 30px;
          }

          @if $i < 4 {
            .article-card__name {
              font-size: 28px;
              line-height: 34px;
              max-height: 28px * 3 * 1.2;
              -webkit-line-clamp: 3;

              @include container {
                font-size: 23px;
              }
            }
          }
        }

        @include desktop {
          min-height: ($container--1920 - 30px * ($i - 1)) / $i;
        }

        @include container {
          min-height: ($container--1280 - 30px * ($i - 1)) / $i;
        }

        @include tablet {
          min-height: calc(((100vw - #{$padding-tablet}) - 30px * (#{$i} - 1)) / #{$i});

          &:not(:first-of-type) {
            margin-top: 10px;
          }
        }

        @include mobile {
          min-height: calc(100vw - #{$padding-mobile*2}) !important;
          width: 100%;
        }
      }
    }

    &--#{$i}.flex-list--mediumheight {
      .flex-list__item {
        min-height: 300px;

        &:nth-of-type(n+3) {
          margin-top: 30px;
        }

        @include mobile {
          width: 100%;

          &:not(:first-of-type) {
            margin-top: 10px;
          }
        }
      }
    }
  }

  &.swiper-wrapper,
  &--nowrap {
    flex-wrap: nowrap;

    .flex-list__item {
      margin-top: 0 !important;
    }
  }

  &.slider-mobile__wrapper {
    @include mobile {
      flex-wrap: nowrap;

      .slider-mobile__slide {
        width: 100%;
        height: auto;
        flex-shrink: 0;
      }
    }
  }

  &--vert {
    flex-direction: column;
  }

  &__item--rad {
    margin-top: 35px;
    margin-bottom: 35px;
    border-radius: 5px;
    overflow: hidden;
  }

  &__item--grow {
    flex-grow: 1;
    align-self: center;
    width: auto;

    @include min-tablet {
      margin-right: 0 !important;
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__item--wide {
    width: calc((100% - 15px) * 2 / 3);
  }

  &--icon {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 21px;

      @include mobile {
        margin-bottom: 23px;
      }
    }

    .page__h4 {
      margin-bottom: 0;
    }

    svg {
      width: 24px;
      height: 24px;
      color: $color-green;
      margin-right: 15px;
      flex-shrink: 0;
    }
  }

  &--rounded {
    li {
      border-radius: 10px;
      overflow: hidden;
      backface-visibility: hidden;

      img {
        border-radius: 10px;
        overflow: hidden;
        backface-visibility: hidden;
      }
    }
  }
}
