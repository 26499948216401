.select {
  position: relative;
  margin-left: $padding-mobile;
  margin-right: $padding-mobile;
  margin-bottom: 25px;

  &__input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    padding: 0 25px;
    width: 100%;
    min-height: 48px;
    border: 1px solid $color-green;
    border-radius: 100px;
    font-family: $font-family-primary-medium;
    font-size: 16px;
    font-weight: 500;
    color: $color-green;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: $color-transparent;
  }

  &__list {
    @include reset-list;
    @include hidden-scrollbar;

    position: absolute;
    z-index: 10;
    display: none;
    top: 55px;
    left: 0;
    width: 100%;
    max-height: 200px;
    background-color: $color-white;
    box-shadow: $shadow-main;
    border-radius: 20px;
    overflow-y: auto;

    .select--active & {
      display: block;
    }
  }

  &__option {
    padding: 10px 25px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    @include hover {
      color: $color-green;
    }

    &--active {
      background-color: $color-very-light-grey;
      border-bottom: none;

      @include hover {
        color: unset;
      }
    }
  }

  svg {
    position: absolute;
    right: 18px;
    top: 17px;
    z-index: 10;
    fill: none;
    transition: transform $default-transition-settings;
  }

  &--active {
    svg {
      transform: rotate(180deg);
    }
  }
}
