.advertising-page {
  .tabs__controls {
    flex-wrap: wrap;
    margin-bottom: -20px;
  }

  .tabs__controls button {
    margin-bottom: 20px;
  }

  .effects .card {
    min-height: 145px;

    @include mobile {
      flex-direction: row;
      min-height: 96px;
      width: 100%;
    }
  }

  .flex-list__item {
    @include mobile {
      margin-bottom: 15px;
      width: 100%;
    }
  }

  .section--advertising .flex-list__item:last-child {
    @include mobile {
      margin-bottom: 0;
    }
  }

  .section--advertising .card__column:first-child {
    @include mobile {
      margin: 0 15px 0 0;
    }
  }

  .section--advertising .card__title {
    margin: 0;

    @include mobile {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .flex-list__item--has-button .card__request-title {
    text-align: center;
  }

  .flex-list__item--has-button .button {
    max-width: 270px;
    margin: 0 auto;

    @include mobile {
      max-width: 210px;
    }
  }

  .section--advertising .card--min-pad:last-of-type {
    padding: 23px 15px;
  }

  .card__request--wide {
    @include mobile {
      margin: 0 auto;
    }
  }

  .card__request-title {
    max-width: none;
    margin: 0 0 15px 0;
    font-weight: 700 !important;

    @include mobile {
      margin: 0 0 15px 0;
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
    }
  }

  .items-list__checked-list {
    padding: 0;
    list-style-type: none;
  }

  .items-list__checked-item {
    position: relative;
    padding-left: 25px;

    &::before {
      @include pseudo;

      left: 0;
      top: 7px;
      width: 14px;
      height: 10px;
      background-image: url("../img/svg/icon-tick.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}
