.analytics-promo {
  .subtitle {
    max-width: 570px;

    @include mobile {
      margin-bottom: 27px;
    }
  }

  .card {
    padding: 40px 31px;
    min-height: 332px;

    @include mobile {
      padding: 40px 33px;
    }
  }

  .card__title {
    max-width: none;
  }

  .card__text {
    max-width: 270px;
  }

  .flex-list__item {
    display: flex;

    @include mobile {
      justify-content: center;
    }
  }
}
