.features {
  .title {
    margin: 0 auto 40px;
    max-width: 550px;
    text-align: center;
  }

  @include mobile {
    .title {
      margin-bottom: 24px;
      max-width: 300px;

      .text-outline {
        &::before {
          background-image: url("../img/svg/text-outline-tall.svg");
        }
      }
    }
  }
}
