$fonts: (
  ("Roboto-regular", "roboto-400", 400, normal),
  ("Roboto-medium", "roboto-500", 500, normal),
  ("Roboto-bold", "roboto-700", 700, normal),
  ("Roboto-extra-bold", "roboto-900", 900, normal),
);

@mixin font-face($family, $file-name, $weight, $style) {
  @font-face {
    font-family: $family;
    src: url("../fonts/" + $file-name + ".woff2") format("woff2"),
      url("../fonts/" + $file-name + ".woff") format("woff");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@each $family, $file-name, $weight, $style in $fonts {
  @include font-face($family, $file-name, $weight, $style);
}
