.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  min-height: 352px;
  border: 1px solid rgba($color: $color-default-black, $alpha: 0.2);
  border-radius: 10px;
  overflow: hidden;
  backface-visibility: hidden;
  transition: box-shadow $default-transition-settings, border-color $default-transition-settings;
  cursor: pointer;

  svg {
    margin-bottom: 15px;
    fill: none;
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 15px;
    min-height: 64px;
    max-width: 230px;
    font-family: $font-family-alt;
    font-size: 24px;
    line-height: 32px;
    color: $color-black;
    text-align: center;
  }

  &__text {
    margin: 0;
    text-align: center;
    letter-spacing: -0.04em;
  }

  @include min-desktop {
    &:hover {
      box-shadow: $shadow-main;
      border-color: transparent;
    }
  }

  @include container {
    padding: 40px 20px;
  }

  @include mobile {
    padding: 40px 25px;
  }

  &--horizontal {
    flex-direction: row;
    align-items: flex-start;
    min-height: 299px;

    svg {
      margin-bottom: 0;
    }

    .card__column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &:first-child {
        flex-shrink: 0;
        margin-right: 30px;
      }
    }

    .card__title {
      max-width: none;
      min-height: auto;
      text-align: left;
    }

    .card__text {
      text-align: left;
    }

    @include container {
      .card__column {
        &:first-child {
          margin-right: 20px;
        }
      }
    }

    @include mobile {
      flex-direction: column;
      padding: 27px 25px 25px;
      min-height: 369px;

      .card__column {
        &:first-child {
          align-self: center;
          margin-right: 0;
          margin-bottom: 14px;
        }
      }
    }
  }

  &--full-width {
    padding: 0;
    min-height: auto;
    flex-direction: row;
    align-items: flex-start;
    border: none;

    svg {
      margin-bottom: 0;
    }

    .card__icon {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 65px;
      width: 380px;
      height: 260px;
      border: 1px solid rgba($color: $color-default-black, $alpha: 0.2);
      border-radius: 10px;
      transition: background $default-transition-settings;

      svg {
        fill: $color-green;
      }
    }

    .card__info {
      padding-top: 10px;

      p {
        margin: 0;
      }
    }

    .title {
      text-align: left;
      letter-spacing: -0.05em;

      @include tablet {
        font-size: 24px;
        line-height: 32px;
      }
    }

    @include min-desktop {
      &:hover {
        box-shadow: none;

        .card__icon {
          background-color: $color-green;
          border: none;

          svg {
            fill: $color-default-white;
          }
        }
      }
    }

    .flex-list__item:nth-child(even) & {
      flex-direction: row-reverse;

      .card__icon {
        margin-right: 0;
        margin-left: 65px;
      }
    }

    @include tablet {
      flex-direction: column;

      .card__icon {
        margin: 0 auto;
      }

      .flex-list__item:nth-child(even) & {
        flex-direction: column;

        .card__icon {
          margin: 0 auto;
        }
      }
    }

    @include mobile {
      min-height: auto;
      padding: 10px 10px 20px;

      .card__icon {
        width: 210px;
        height: 140px;

        svg {
          width: 80px;
          height: 80px;
        }
      }

      .title {
        text-align: center;
      }

      .card__info {
        padding-top: 25px;
      }

      p {
        text-align: center;
      }
    }
  }

  &--vertical {
    padding: 30px 20px;
    min-height: 354px;

    .card__icon {
      margin-bottom: 10px;
      font-family: $font-family-primary-bold;
      font-size: 54px;
      line-height: 63px;
      font-weight: 700;
    }

    h3 {
      font-family: $font-family-alt;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      color: $color-black;
    }

    .card__request {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        margin: 0;
        margin-bottom: 15px;
        width: 100%;
        font-family: $font-family-alt;
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        text-align: center;

        @include mobile {
          font-size: 18px;
          line-height: 28px;
        }
      }

      p {
        margin: 0;
        margin-bottom: 30px;
        max-width: 275px;
        text-align: center;
      }
    }

    @include mobile {
      min-height: 352px;
      padding: 30px 30px 25px;
    }
  }

  &--listed {
    align-items: flex-start;
    min-height: 512px;

    .card__title {
      max-width: none;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      text-align: left;
      min-height: auto;
    }

    .card__list {
      @include reset-list;

      li {
        position: relative;
        margin-bottom: 10px;
        padding-left: 28px;
        font-size: 17px;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          @include pseudo;

          left: 0;
          top: 7px;
          width: 14px;
          height: 10px;
          background-image: url("../img/svg/icon-tick.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }

        @include tablet {
          padding-left: 20px;
          font-size: 14px;
        }

        @include mobile {
          margin-bottom: 17px;
        }
      }
    }

    .card__list-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .card__list-wrapper > .card__list:first-child {
      margin-right: 30px;
    }

    & > p {
      margin: auto 0 15px;
      font-family: $font-family-primary-extrabold;
      font-weight: 900;
      font-size: 36px;
      line-height: 44px;
      color: $color-green;
      display: flex;
      align-items: center;

      img {
        margin-left: 5px;
        width: 28px;
        height: 28px;
        margin-bottom: 3px;
      }

      @include tablet {
        font-size: 30px;
      }

      @include mobile {
        font-size: 36px;
      }
    }

    @include mobile {
      min-height: 492px;
      padding: 30px;

      .card__title {
        margin-bottom: 25px;
        min-height: auto;
        font-size: 23px;
        line-height: 24px;
      }

      .card__list {
        li {
          padding-left: 28px;
          font-size: 17px;
        }
      }
    }
  }

  &--feedback {
    padding: 0;

    svg {
      margin-bottom: 0;
    }

    .card__header {
      display: flex;
      align-items: center;
      padding: 30px;
      width: 100%;
      border-bottom: 1px solid $color-very-light-grey;

      @include mobile {
        padding: 29px 18px;
      }
    }

    .card__img {
      flex-shrink: 0;
      margin-right: 20px;
    }

    .card__name {
      margin: 0;
      margin-bottom: 5px;
      font-family: $font-family-alt;
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
      color: $color-black;
    }

    .card__status {
      margin: 0;
      font-size: 14px;
      line-height: 24px;
    }

    .card__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 30px 30px 35px;

      .button {
        margin-top: auto;
        min-width: 225px;
        align-self: flex-start;
      }

      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 20px 35px;

        .button {
          min-width: 223px;
        }
      }
    }

    .card__stars {
      margin-bottom: 12px;

      @include mobile {
        margin-bottom: 6px;
      }
    }

    .card__title {
      margin: 0;
      margin-bottom: 15px;
      min-height: auto;
      font-family: $font-family-alt;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      color: $color-black;
    }

    .card__text {
      margin-bottom: 25px;
      text-align: left;

      @include mobile {
        margin-bottom: 34px;
      }
    }
  }

  &--article {
    padding: 0;

    .card__img {
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .card__info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 25px 40px 40px;

      .button {
        margin-top: auto;
      }

      @include mobile {
        padding: 25px 30px 40px;
      }
    }

    .card__title {
      justify-content: center;
      margin-bottom: 15px;
      min-height: auto;
      max-width: none;
      text-align: center;
      font-weight: 400;

      @include mobile {
        margin-bottom: 11px;
      }
    }

    .card__text {
      position: relative;
      margin-bottom: 0;
      flex-grow: 1;

      &::before {
        @include pseudo;

        left: -40px;
        right: -40px;
        bottom: 0;
        height: 75px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, $color-default-white 100%);
      }
    }
  }

  &--min-pad {
    padding: 40px 15px;
    width: 100%;
  }

  &--small {
    min-height: auto;
    padding: 35px 22px;

    .card__text {
      font-size: 20px;
      line-height: 28px;
      font-family: $font-family-alt;
      color: $color-black;
    }
  }

  &--callback {
    padding-left: 60px;
    padding-right: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-image: url("../img/bg/callback-card-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;

    @include retina {
      background-image: url("../img/bg/callback-card-bg@2x.jpg");
    }

    .card__text {
      margin-bottom: 30px;
      text-align: left;
    }

    @include container {
      .card__title {
        text-align: left;
      }
    }

    @include tablet {
      padding-left: 40px;
      padding-right: 220px;
    }

    @include mobile {
      background-image: none;

      .card__title {
        max-width: none !important;
      }

      .card__text {
        max-width: none !important;
      }
    }
  }

  &--centered-mobile {
    @include mobile {
      .card__title {
        text-align: center;
      }

      .card__text {
        padding-right: 0 !important;
        text-align: center;
      }
    }
  }
}

@include mobile {
  .card__list:first-child {
    margin-right: 3px;
  }
}

//@include mobile-xs {
//  margin-right: 20px;
//}


.flex-list__item:nth-child(even) .card--full-width .card__icon {
  @include mobile {
    margin-bottom: 25px;
  }
}
