.trophy {
  border: 1px solid rgba($color-black, 0.2);
  border-radius: 10px;
  display: grid;
  grid-template-columns: 140px auto;
  overflow: hidden;

  @include mobile {
    display: none;
  }

  &__icon {
    padding: 40px;
    padding-top: 41px;
    background-color: $color-green;
  }

  p {
    margin: 0;
    padding: 40px;
    font-size: 24px;
    line-height: 32px;
    color: $color-black;
  }
}
