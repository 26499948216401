.items-list {
  @include reset-list;

  counter-reset: list;

  &__item {
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 40px;
    padding-left: 8.56%;
    border-bottom: 1px solid $color-green;
    counter-increment: list;

    &::before {
      content: counter(list) ".";
      position: absolute;
      left: 0;
      top: 0;
      font-family: $font-family-alt;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      color: $color-green;
    }

    h3 {
      margin: 0 0 20px;
      font-family: $font-family-alt;
      font-size: 24px;
      line-height: 30px;
      font-weight: 400;
      color: $color-black;
    }

    p {
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include mobile {
      margin-bottom: 27px;
      padding-left: 0;
      padding-bottom: 27px;

      h3 {
        padding-left: 39px;
      }
    }
  }

  &--column {
    .items-list__item {
      display: flex;
      padding-right: 65px;
      margin-bottom: 30px;

      @include mobile {
        padding-right: 0;
      }
    }

    .items-list__column {
      &:first-child {
        flex-shrink: 0;
        margin-right: 10.73%;
        width: 37%;
      }
    }

    .items-list__column-weight {
        p:nth-child(odd) {
          font-weight: 500;
          font-style: normal;
          font-size: 20px;
          line-height: 30px;
          color: #202020;
        }
    }

    @include mobile {
      .items-list__item {
        flex-direction: column;
      }

      .items-list__column {
        &:first-child {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  &--listed {
    .items-list__list {
      @include reset-list;

      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
