.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background-color: rgba($color-default-black, 0.6);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  transition: opacity $modal-transition-settings,
    transform $modal-transition-settings,
    visibility $modal-transition-settings;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;

    @include mobile {
      padding: 60px 16px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    width: 600px;
    padding: 40px;

    background-color: $color-default-white;

    @include mobile {
      width: 100%;
      padding: 20px;
    }
  }

  &__close-btn {
    @include reset-btn;
    position: absolute;
    width: 50px;
    height: 50px;
    right: -15px;
    top: -15px;
    opacity: 1;
    transition: opacity $default-transition-settings;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-grey;
    transform: rotate(45deg) scale(1.5);

    svg {
      fill: none;
    }

    &:focus {
      outline: none;
    }

    @include hover {
      opacity: 0.7;
    }

    @include tablet {
      top: 7px;
      right: 8px;
      width: 30px;
      height: 30px;
      transform: rotate(45deg);
    }

    @include mobile {
      top: -11px;
      right: -9px;
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include mobile {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    display: none;
  }

  &--active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    display: block !important;
  }

  &--video {
    .modal__content {
      background-color: transparent;
    }

    .modal-video-wrapper {
      width: 70vw;

      iframe {
        opacity: 1;
        pointer-events: auto;
      }

      @include tablet {
        width: 85vw;
      }

      @include mobile {
        width: 100%;
      }
    }
  }

  &--contest {
    .modal__content {
      max-width: 490px;
      padding: 35px 70px;
      border-radius: 10px;

      @include mobile {
        max-width: 330px;
        padding: 25px;
      }
    }

    .title {
      margin-bottom: 25px;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;

      @include mobile {
        font-size: 18px;
        line-height: 28px;
      }
    }

    .modal__close-btn {
      @include reset-btn;
      position: absolute;
      width: 22px;
      height: 22px;
      right: 20px;
      top: 20px;
      opacity: 1;
      transition: opacity $default-transition-settings;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-grey;
      transform: none;

      @include tablet {
        top: 15px;
        right: 14px;
      }

      @include mobile {
        top: 11px;
        right: 13px;
        width: 16px;
        height: 16px;
      }
    }
  }

  &--reg {
    .modal__content {
      padding: 35px 30px;

      @include mobile {
        padding: 25px;
      }
    }
  }

  &--rate {
    .modal__content {
      padding: 35px 30px;

      @include mobile {
        padding: 25px;
      }
    }
  }

  &--pro {
    .title {
      @include mobile {
        max-width: 150px;
        margin: 0 auto;
        margin-bottom: 25px;
      }
    }
  }
}
