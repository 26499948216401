.lesson-page {
  .items-list__checked-list {
    list-style: none;
  }

  .items-list__checked-item {
    position: relative;
    padding-left: 25px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 7px;
      width: 14px;
      height: 10px;
      background-image: url("../img/svg/icon-tick.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  .container {
    @include mobile {
      padding: 0 12px;
    }
  }

  .form input,
  .button.el_button {
    width: 310px;

    @include mobile {
      width: 100%;
    }
  }

  .promo--target .card {
    padding: 30px 20px;
  }

  .video-block--target .title:first-of-type {
    @include mobile {
      display: block;
    }
  }

  .card__title,
  .subtitle {
    max-width: 100%;
  }

  .videos {
    .subtitle {
      max-width: 727px;
    }
  }

  .reach-out .card--horizontal {
    padding-right: 35px;
  }

  .reporting {
    .card,
    .card__title {
      align-items: flex-start;

      @include mobile {
        align-items: center;
      }
    }

    .card__title {
      min-height: auto;
    }

    .card__text {
      text-align: left;
    }

    .title {
      @include mobile {
        br {
          display: none;
        }
      }
    }
  }

  .check-form__inner {
    .subtitle {
      @include mobile {
        &:first-of-type {
          display: none;
        }
      }

      &--mobile {
        display: none;

        @include mobile {
          display: block;
          margin-bottom: 25px;
        }
      }
    }
  }

  .container .slider-mobile {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -12px;
    margin-right: -12px;
  }
}
