.prices {
  &__inner {
    .title {
      margin-left: -5px;
      margin-right: -5px;
    }

    .button {
      width: 100%;
      min-width: auto;
    }
  }

  .flex-list__item {
    display: flex;
    justify-content: center;

    &--column {
      flex-direction: column;
    }
  }

  @include mobile {
    &__inner {
      & > p {
        margin: 0;
        margin-bottom: 26px;
      }
    }
  }
}

.prices--direct {
  @include mobile {
    padding-bottom: 25px;
  }

  .subtitle {
    max-width: 840px;
  }

  .card__title {
    margin-bottom: 21px;
  }

  .card--listed .card__list li {
    margin-bottom: 16px;
  }

  .card--listed {
    @include tablet {
      width: 100%;
    }
  }

  .card--listed .card__list {
    margin-bottom: 16px;

    @include tablet {
      margin-bottom: 16px;
    }
  }

  .card--listed .card__list-wrapper {
    @include tablet {
      flex-direction: column;
    }
  }
}

.prices--target {
  @include mobile {
    padding-bottom: 30px;
  }

  .subtitle {
    max-width: 978px;
  }

  .card--listed .card__title {
    margin-bottom: 20px;
  }

  .card--listed .card__list li {
    margin-bottom: 16px;
  }

  .prices__inner > p {
    @include mobile {
      margin-bottom: 30px;
    }
  }

  .card--listed {
    @include mobile {
      min-height: 508px;
      width: 100%;
      max-width: 450px;
    }
  }
}

.prices--context {
  padding-top: 75px;

  @include mobile {
    padding-top: 35px;
  }

  .subtitle {
    max-width: 975px;
  }
}
