.direct-how-scheme {
  .subtitle {
    max-width: none;
  }

  @media (max-width: 1280px) {
    display: none;
  }

  @include mobile {
    display: none;
  }
}

.direct-how-scheme__list,
.direct-how-scheme__sublist {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;

  list-style: none;

  li:not(.direct-how-scheme__item--multiple-arrow-right) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-weight: 500;

    border-radius: 10px;

    transition: box-shadow $default-transition-settings, border-color $default-transition-settings;
    cursor: pointer;

    &:hover {
      box-shadow: $shadow-main;
      border-color: transparent;
    }
  }
}

.direct-how-scheme__item {
  height: auto;

  &:first-of-type {
    margin-right: 30px;
  }

  &:last-of-type {
    width: 190px;
    margin-left: 30px;
  }

  &--accented,
  &--accented-arrow-right {
    padding: 10px 14px;
    width: 255px;
    max-width: 255px;
    min-height: 90px;

    font-size: 20px;
    line-height: 30px;
    color: $color-black;

    border: 2px solid $color-green;
  }

  &--accented-arrow-right,
  &--multiple-arrow-right {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      right: -27px;
      top: 50%;
      transform: translateY(-50%);
      width: 21px;
      height: 16px;

      background: url("../img/svg/arrow-small.svg") center center no-repeat;
    }
  }
}


.direct-how-scheme__sublist {
  position: relative;
  flex-direction: column;
  margin-left: 95px;

  li {
    width: 255px;
    height: 50px;

    font-size: 16px;
    color: $color-dolphin;

    border: 1px solid rgba($color: $color-default-black, $alpha: 0.2);

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -95px;
    transform: translateY(-50%);

    display: block;
    width: 95px;
    height: 352px;

    background: url("../img/svg/direct-scheme.svg") center center no-repeat;
  }
}
