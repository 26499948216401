.videos {
  @include mobile {
    padding-bottom: 24px;
  }

  .swiper-slide {
    position: relative;
    display: flex;
    max-width: 560px;
    margin-right: 0;
    border-radius: 10px;
    overflow: hidden;
    backface-visibility: hidden;

    @include min-container {
      max-width: 660px;
    }

    .js-video-preview {
      flex-grow: 1;
    }

    @include min-desktop {
      &.prev-1 {
        transform: translate3d(270px, -70px, -400px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.next-1 {
        transform: translate3d(-270px, -70px, -400px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.prev-2 {
        transform: translate3d(700px, -150px, -573px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.next-2 {
        transform: translate3d(-700px, -150px, -573px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.prev-3 {
        transform: translate3d(1300px, -210px, -591px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.next-3 {
        transform: translate3d(-1300px, -210px, -591px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.prev-4 {
        transform: translate3d(1900px, -258px, -591px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.next-4 {
        transform: translate3d(-1900px, -258px, -591px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.swiper-slide-active {
        transform: translate3d(0, 0, 0) rotateX(0deg) rotateY(0deg) !important;
      }

      @for $i from 5 through 10 {
        &.prev-#{$i},
        &.next-#{$i} {
          opacity: 0;
        }
      }
    }

    @include min-container {
      &.prev-1 {
        transform: translate3d(310px, -93px, -400px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.next-1 {
        transform: translate3d(-310px, -93px, -400px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.prev-2 {
        transform: translate3d(832px, -175px, -573px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.next-2 {
        transform: translate3d(-832px, -175px, -573px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.prev-3 {
        transform: translate3d(1543px, -239px, -591px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.next-3 {
        transform: translate3d(-1543px, -239px, -591px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.prev-4 {
        transform: translate3d(2267px, -295px, -591px) rotateX(0deg) rotateY(0deg) !important;
      }

      &.next-4 {
        transform: translate3d(-2267px, -295px, -591px) rotateX(0deg) rotateY(0deg) !important;
      }

      @for $i from 5 through 10 {
        &.prev-#{$i},
        &.next-#{$i} {
          opacity: 0;
        }
      }
    }
  }

  .video__wrapper {
    padding-top: 55.5%;
  }

  @include mobile {
    .video__wrapper {
      padding-top: 61.5%;
    }

    .subtitle {
      margin-bottom: 18px;
    }
  }

  .slider-mobile__pagination {
    @include mobile {
      margin-top: 10px;
    }
  }
}
