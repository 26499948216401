.direct-page {
  .quotes {
    border: none;
  }

  .tabs__control-btn {
    margin-bottom: 20px;
  }

  .swiper-slide:nth-of-type(1),
  .swiper-slide:nth-of-type(2) {
    .tabs__controls {
      max-width: 800px;
      flex-wrap: wrap;
      margin: 0 auto;
    }
  }

  .swiper-slide:nth-of-type(3),
  .swiper-slide:nth-of-type(4),
  .swiper-slide:nth-of-type(5) {
    .tabs__controls {
      max-width: none;
    }
  }

  .items-list__checked-list {
    list-style: none;
  }

  .items-list__checked-item {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 7px;
      width: 14px;
      height: 10px;
      background: url(../img/svg/icon-tick.svg) center center no-repeat;
      background-size: contain;
    }
  }
}

