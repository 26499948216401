.tasks {
  .card {
    min-height: auto;

    @include tablet {
      width: 100%;
    }
  }

  .card--horizontal .card__column:first-child {
    @include mobile {
      align-self: flex-start;
    }
  }

  .card__list {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 149px);
    gap: 15px;

    @include desktop {
      grid-template-columns: repeat(3, 137px);
    }

    @include tablet {
      grid-template-columns: repeat(2, 127px);
    }

    @include mobile {
      display: block;
    }
  }
}
