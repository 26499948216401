.seo-promo .card {
  padding: 23px;
}

.seo-promo .card__title {
  max-width: none;
}

.text-underline-seo::before {
  background-image: url("../img/content/seo/underline.svg");
}

.seo-scheme {
  padding-bottom: 22px;

  @media (max-width: 1023px) {
    display: none;
  }
}

.seo-page .flex-list__item--has-button .button {
  max-width: 270px;
  width: 100%;
}

.seo-page .flex-list__item {
  @media (max-width: 1024px) {
    margin-bottom: 15px;
  }
}

.seo-video .video:first-child {
  @include mobile {
    display: none;
  }
}

.seo-video .video:last-child {
  display: none;

  @include mobile {
    display: block;
  }
}

.seo-video .video__img-wrapper {
  height: auto;
}

.seo-video .icon-play {
  left: 50%;
  top: 46%;
}

.reasons-seo .card {
  min-height: 232px;
  @include mobile {
    min-height: 350px;
  }
}

.seo-scheme-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 730px;
  margin: 0;
  padding: 0;
  align-items: center;
  background: url("../img/content/seo/seo-scheme-bg.svg") 130px 23px no-repeat;

  @media (max-width: 1279px) {
    background: url("../img/content/seo/seo-scheme-bg.svg") 70px 23px no-repeat;
  }
}

.seo-scheme__item {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: auto;
  text-align: center;
  font-size: 16px;
  color: #767677;
  background: #ffffff;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.1);
  }
}

.seo-scheme__item--small-left {
  padding: 12px 0;
  margin-bottom: 20px;
  min-width: 190px;
  width: 190px;
  align-self: flex-start;
}

.seo-scheme__item--small-right {
  padding: 12px 0;
  margin-bottom: 20px;
  min-width: 190px;
  width: 190px;
  align-self: flex-end;
}

.seo-scheme__item--medium {
  text-align: center;
  padding: 22px 0;
  margin-top: 63px;
  margin-bottom: 44px;
  width: 255px;
}

.seo-scheme__item--medium-left {
  padding: 22px 20px;
  margin-top: 63px;
  margin-bottom: 44px;
  width: 255px;
  align-self: flex-start;
}

.seo-scheme__item--medium-right {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70px;
  padding: 10px 50px;
  margin-top: 63px;
  margin-bottom: 44px;
  width: 255px;
  align-self: flex-end;
}

.seo-scheme__item--active-medium {
  border-color: #18ba60;
  padding: 19px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #202020;
  margin-top: 25px;
  margin-bottom: 48px;
  width: 350px;

  &:hover {
    background: #18ba60;
    color: #ffffff;
  }
}

.seo-scheme__item--active-bold {
  border-color: #18ba60;
  padding: 30px 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  color: #202020;
  margin-top: 43px;
  margin-bottom: 31px;
  width: 310px;

  &:hover {
    background: #18ba60;
    color: #ffffff;
  }
}
