.processes {
  border-bottom: none;
  padding-bottom: 46px;

  .subtitle {
    max-width: 820px;
  }

  @include mobile {
    display: none;
  }
}
