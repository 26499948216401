.slider-mobile {
  position: relative;

  @include tablet {
    &--btn-left {
      .js-slider-controls {
        display: inline-block;
        margin-bottom: 25px;
      }
    }

    @include mobile {
      width: 100%;

      .flex-list.swiper-wrapper {
        flex-wrap: nowrap;
      }

      &--center {
        .slider-mobile__slide {
          padding-left: 30px;
          padding-right: 30px;
        }

        .slider-mobile__controls-btn {
          margin-top: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          &:first-of-type {
            left: $padding-mobile;
          }

          &:last-of-type {
            right: $padding-mobile;
          }
        }
      }

      &--btn-left {
        .slider-mobile__slide {
          padding-left: 0;
          padding-right: 0;
        }

        .pic-card {
          padding: 56px 0 52px;
        }
      }
    }
  }

  &__controls-btn {
    z-index: 10;

    @include min-tablet {
      display: none !important;
    }
  }

  &__slide > img {
    @include mobile {
      object-fit: cover;
      width: 100%;
    }
  }

  &__pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 0 38px;

    .swiper-pagination-bullet {
      margin: 0 8px 10px 0 !important;
      display: block;
      width: 12px;
      height: 12px;
      background-color: $color-very-light-grey;

      &:last-child {
        margin-right: 0 !important;
      }

      &-active {
        background-color: $color-green;
      }
    }

    @include min-tablet {
      // display: none;
      visibility: hidden;
      height: 0;
      margin-top: 0;
    }
  }
}

.controls-btn {
  display: inline-flex;
  z-index: 10;
  margin-top: 15px;

  & + & {
    margin-left: 12px;
  }

  & + .swiper-wrapper {
    margin-top: 25px;
  }
}

.container .slider-mobile {
  @include mobile {
    min-width: 100vw;
    padding-left: $padding-mobile;
    padding-right: $padding-mobile;
    margin-left: -$padding-mobile;
    margin-right: -$padding-mobile;
  }
}

.arrow-btn {
  @include reset-btn;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid $color-default-white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-default-white;
  transition: background $default-transition-settings;

  svg {
    transition: fill $default-transition-settings;
  }

  @include min-desktop {
    &:hover {
      background-color: $color-green;

      svg {
        fill: $color-default-white;
      }
    }
  }

  &--inverted {
    svg {
      transform: rotate(180deg);
    }

    @include hover {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &--shadow {
    background-color: $color-default-white;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);
    color: $color-green;
  }

  &.swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @include container {
    width: 40px;
    height: 40px;

    svg {
      width: 25px;
      height: 15px;
    }
  }
}
