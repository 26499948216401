.sell-more {
  .subtitle {
    margin-bottom: 10px;
  }

  .card {
    padding-bottom: 21px;
    min-height: auto;
  }

  .flex-list {
    @include mobile {
      flex-direction: column;

      .card {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .slider-full__controls-btn {
    &:last-of-type {
      transform: translate(50%, -70%);

      @include desktop {
        right: 157px;
      }

      @include container {
        transform: translate(50%, -57%);
      }
    }

    &:first-of-type {
      transform: translate(-50%, -70%);

      @include desktop {
        left: 157px;
      }

      @include container {
        transform: translate(-50%, -57%);
      }
    }
  }

  .swiper-container {
    padding-bottom: 77px;
  }

  .js-slider-mobile-pagination {
    display: flex;
  }

  .slider-mobile__pagination {
    margin-top: 35px;
  }
}
