.slider-inner {
  padding-left: 30px;
  margin-left: -30px;

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .swiper-pagination-bullet {
      margin: 0 8px 0 0 !important;
      display: block;
      width: 12px;
      height: 12px;
      background-color: $color-very-light-grey;

      &:last-child {
        margin-right: 0 !important;
      }

      &-active {
        background-color: $color-green;
      }
    }

    @include min-desktop {
      display: none;
    }
  }

  @include mobile {
    // margin-right: -15px;
    // margin-left: -15px;
    // padding-left: 15px;
    // padding-right: 15px;
  }
}
