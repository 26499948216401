.header-buttons {
  display: flex;
  justify-content: center;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .button {
    width: 348px;

    @include mobile {
      max-width: 320px;
      padding: 0;
    }

    @include mobile-xs {
      width: 280px;
    }
  }

  .button:first-child {
    background-color: $color-transparent;
    color: $color-green;
    margin-right: 20px;

    @include mobile {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
}
