.social {
  .card__title {
    min-height: auto;
    position: relative;
    transition: color $default-transition-settings;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $color-black;
      bottom: 2px;
      left: 0;
      transition: opacity $default-transition-settings;
    }

    &:hover {
      color: $color-green;
      transition: color $default-transition-settings;

      &::after {
        opacity: 0;
        transition: opacity $default-transition-settings;
      }
    }
  }

  .card {
    width: 100%;
  }

  .card--small .card__text {
    color: $color-grey;
    font-size: 17px;
    line-height: 24px;
  }
}
