@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

// Desktop first

@mixin viewport--xl {
  @media (max-width: $viewport--xxl - 1) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: $viewport--xl - 1) {
    @content;
  }
}

@mixin container {
  @media (max-width: $container - 1) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $viewport--lg - 1) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $viewport--md - 1) {
    @content;
  }
}

@mixin mobile-xs {
  @media (max-width: $viewport--sm - 1) {
    @content;
  }
}

// Mobile first

@mixin min-tablet {
  @media (min-width: $viewport--md) {
    @content;
  }
}

@mixin min-desktop {
  @media (min-width: $viewport--lg) {
    @content;
  }
}

@mixin min-container {
  @media (min-width: $container) {
    @content;
  }
}

/* @mixin viewport--md {
  @media (min-width: $viewport--md) {
    @content;
  }
}

@mixin viewport--lg {
  @media (min-width: $viewport--lg) {
    @content;
  }
}

@mixin viewport--xl {
  @media (min-width: $viewport--xl) {
    @content;
  }
}

@mixin viewport--xxl {
  @media (min-width: $viewport--xxl) {
    @content;
  }
} */

@mixin hover {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      @content;
    }
  }
}

@mixin reset-list {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

@mixin reset-btn {
  cursor: pointer;
  padding: 0;
  background-color: $color-transparent;
  border: none;
  outline: none;
}

@mixin hidden-scrollbar {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* make scrollbar transparent */
  }

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

@mixin placeholder {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @content;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }
}

@mixin pseudo {
  content: "";
  position: absolute;
  display: block;
}

@mixin first-letter-caps {
  &::first-letter {
    text-transform: capitalize;
  }
}

@mixin icon-chevron($width, $border) {
  display: block;
  width: $width;
  height: $width;
  transform: rotate(-45deg);
  border-bottom: $border solid $color-green;
  border-left: $border solid $color-green;
}
