.slider-desk {
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .swiper-pagination-bullet {
      margin: 0 8px 0 0 !important;
      display: block;
      width: 12px;
      height: 12px;
      background-color: $color-very-light-grey;

      &:last-child {
        margin-right: 0 !important;
      }

      &-active {
        background-color: $color-green;
      }
    }

    @include mobile {
      display: none;
    }
  }
}

.slider-desk__slide {
  @include mobile {
    width: 100%;
  }
}
