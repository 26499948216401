.questions {
  &__inner {
    h2 {
      margin-bottom: 20px;
    }
  }

  .show-more__content {
    margin: 0 -40px;
    padding: 0 40px;
    max-height: 462px;

    @include mobile {
      max-height: 600px;
    }
  }

  .accordion {
    margin-bottom: 40px;

    .accordion__item:hover {
      box-shadow: $shadow-main;
      border-color: $color-transparent;
    }
  }

  @include mobile {
    &__inner {
      & > p {
        margin-bottom: 21px;
      }
    }
  }
}

.questions--target {
  .accordion__content p {
    @include mobile {
      margin-top: 0;
    }
  }

  .accordion__item--active .accordion__header {
    @include mobile {
      margin-bottom: 22px;
    }
  }
}
