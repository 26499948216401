.strategy {
  .title {
    margin-bottom: 60px;
  }

  .flex-list__item {
    display: block;
  }

  @include mobile {
    .title {
      margin-bottom: 23px;
    }

    .show-more__content {
      max-height: 709px;
    }
  }
}
