.chart {
  position: relative;
  display: flex;
  justify-content: space-between;

  &__center {
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 328px;
    height: 328px;
    border-radius: 50%;
    border: 3px solid $color-green;
    background-color: $color-white;
    transform: translate(-27%, -45%);
    transition: background $default-transition-settings;
    cursor: pointer;
    z-index: 2;

    p {
      font-family: $font-family-primary-medium;
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: $color-black;
      text-align: center;
      transition: color $default-transition-settings;
    }

    @include min-desktop {
      &:hover {
        background-color: $color-green;

        p {
          color: $color-white;
        }
      }
    }

    @include tablet {
      width: 250px;
      height: 250px;
      transform: translate(-20%, -41%);

      p {
        font-size: 24px;
      }
    }
  }

  &__list {
    @include reset-list;
  }

  &__left-col {
    max-width: 37%;

    li {
      position: relative;
      margin-bottom: 23px;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        margin-bottom: 15px;
      }

      p {
        margin: 0;
        font-size: 20px;
        line-height: 28px;

        span {
          font-family: $font-family-primary-medium;
          font-weight: 500;
          color: $color-black;
        }

        @include tablet {
          font-size: 16px;
        }
      }

      .chart__line {
        stroke: #a2a2a2;
      }

      &:nth-child(1) {
        .chart__line {
          transform: translate(85%, 115%) rotate(58deg);

          @include tablet {
            transform: translate(82%, 147%) rotate(66deg);
          }
        }
      }

      &:nth-child(2) {
        .chart__line {
          transform: translate(79%, 79%) rotate(38deg);

          @include container {
            transform: translate(104%, 92%) rotate(46deg);
          }
        }
      }

      &:nth-child(3) {
        .chart__line {
          transform: translate(92%, 11%) rotate(15deg);

          @include tablet {
            transform: translate(100%, -6%) rotate(3deg);
          }
        }
      }

      &:nth-child(4) {
        .chart__line {
          transform: translate(63%, -16%) rotate(0deg);

          @include container {
            transform: translate(82%, -37%) rotate(-15deg);
          }
        }
      }
    }
  }

  &__line {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(76%, 130%);
    z-index: 1;
  }

  &__right-col {
    display: flex;
    align-items: center;
    width: 23%;

    li {
      position: relative;
      margin-bottom: 23px;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        margin-bottom: 15px;
      }

      .chart__line {
        right: auto;
        left: 0;
        stroke: $color-green;
      }

      p {
        margin: 0;
        font-size: 20px;
        line-height: 28px;
        font-family: $font-family-primary-medium;
        font-weight: 500;
        color: $color-black;
      }

      &:nth-child(1) {
        .chart__line {
          transform: translate(-117%, 75%) rotate(2deg);

          @include tablet {
            transform: translate(-92%, 108%) rotate(-23deg);
          }
        }
      }

      &:nth-child(2) {
        padding-left: 30px;

        .chart__line {
          transform: translate(-109%, 31%) rotate(28deg);

          @include tablet {
            transform: translate(-99%, 50%) rotate(8deg);
          }
        }
      }

      &:nth-child(3) {
        padding-left: 30px;

        .chart__line {
          transform: translate(-110%, 33%) rotate(26deg);

          @include tablet {
            transform: translate(-102%, 7%) rotate(39deg);
          }
        }
      }

      &:nth-child(4) {
        .chart__line {
          transform: translate(-117%, -19%) rotate(56deg);

          @include tablet {
            transform: translate(-104%, -24%) rotate(71deg);
          }
        }
      }
    }

    @include tablet {
      max-width: 20%;
    }
  }

  &--round {
    .chart__left-col,
    .chart__right-col {
      max-width: 360px;

      li {
        svg {
          fill: none;
        }

        p {
          font-size: 20px;
          line-height: 28px;
          font-family: $font-family-primary-medium;
          font-weight: 500;
          color: $color-black;
        }
      }

      @include container {
        max-width: 270px;
      }

      @include tablet {
        max-width: 200px;

        li {
          p {
            font-size: 17px;
          }
        }
      }
    }

    .chart__left-col {
      li {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 30px;

        p {
          text-align: right;
        }

        &:nth-child(1) {
          .chart__line {
            transform: translate(104%, 72%) rotate(52deg);

            @include tablet {
              transform: translate(119%, 111%) rotate(62deg);
            }
          }
        }

        &:nth-child(2) {
          padding-right: 29px;

          .chart__line {
            transform: translate(94%, 25%) rotate(26deg);

            @include tablet {
              transform: translate(113%, 54%) rotate(41deg);
            }
          }
        }

        &:nth-child(3) {
          padding-right: 29px;

          .chart__line {
            transform: translate(94%, 25%) rotate(27deg);

            @include tablet {
              transform: translate(116%, -10%) rotate(9deg);
            }
          }
        }

        &:nth-child(4) {
          .chart__line {
            transform: translate(102%, -26%) rotate(-2deg);

            @include tablet {
              transform: translate(114%, -29%) rotate(-12deg);
            }
          }
        }
      }
    }

    .chart__right-col {
      width: 360px;

      li {
        margin-bottom: 30px;

        svg {
          margin-bottom: 10px;
        }

        &:nth-child(1) {
          .chart__line {
            transform: translate(-103%, 72%) rotate(1deg);

            @include tablet {
              transform: translate(-110%, 81%) rotate(-5deg);
            }
          }
        }

        &:nth-child(2) {
          .chart__line {
            transform: translate(-95%, 25%) rotate(28deg);

            @include tablet {
              transform: translate(-113%, 55%) rotate(5deg);
            }
          }
        }

        &:nth-child(3) {
          .chart__line {
            transform: translate(-95%, 24%) rotate(28deg);

            @include tablet {
              transform: translate(-115%, 14%) rotate(40deg);
            }
          }
        }

        &:nth-child(4) {
          .chart__line {
            transform: translate(-101%, -28%) rotate(57deg);

            @include tablet {
              transform: translate(-109%, -28%) rotate(64deg);
            }
          }
        }
      }
    }

    .chart__line {
      stroke: $color-green !important;
    }

    .chart__center {
      transform: translate(-50%, -55%);
    }
  }
}
