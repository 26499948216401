.target-page {
  .items-list__checked-list {
    list-style: none;
  }

  .items-list__checked-item {
    position: relative;
    padding-left: 25px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 7px;
      width: 14px;
      height: 10px;
      background-image: url("../img/svg/icon-tick.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}
