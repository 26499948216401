.context-page {
  .card--centered-mobile .card__title {
    @include mobile {
      margin: 8px auto 14px;
    }
  }

  .promo--context {
    .card {
      min-height: 492px;
      padding: 40px 29px;

      @include mobile {
        min-height: 437px;
        padding: 29px 20px;
      }
    }

    svg {
      margin-bottom: 28px;

      @include mobile {
        margin-bottom: 20px;
      }
    }

    .card__title {
      margin-bottom: 18px;
      max-width: unset;
      min-height: unset;

      @include mobile {
        margin-bottom: 15px;
      }
    }

    .card__text {
      letter-spacing: normal;
    }
  }

  .services {
    .title {
      @include mobile {
        hyphens: none;
      }

      @include mobile-xs {
        hyphens: manual;
      }
    }

    .card {
      padding-right: 42px;

      @include mobile {
        padding-right: 25px;
      }
    }

    .card__icon {
      @include mobile {
        width: 100%;
      }
    }

    .card__title {
      margin-bottom: 16px;
    }

    .subtitle {
      max-width: 675px;

      @include mobile {
        margin-bottom: 28px;
      }
    }

    .card__info .title {
      margin-top: 10px;
      letter-spacing: normal;
    }
  }

  .services--no-padding {
    .card {
      padding-right: 0;

      @include mobile {
        padding-right: 10px;
      }
    }

    .subtitle {
      max-width: 635px;
    }

    .flex-list__item:nth-child(even) .card--full-width .card__icon {
      margin-bottom: 0;
    }

  }

  .card--horizontal {
    min-height: unset;

    @include mobile {
      min-height: 390px;
    }

    .card__text {
      letter-spacing: -.005em;
    }
  }

  .items-list__checked-list {
    list-style: none;
  }

  .items-list__checked-item {
    position: relative;
    padding-left: 42px;
    margin-bottom: 16px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 7px;
      width: 14px;
      height: 10px;
      background-image: url("../img/svg/icon-tick.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  .stages {

    @include tablet {
      display: none;
    }
  }

  .possibilities {
    @include mobile {
      padding-bottom: 30px;
    }

    .subtitle {
      @include mobile {
        margin-bottom: 27px;
      }
    }

    .card {
      padding-right: 40px;

      @include mobile {
        min-height: 479px;
      }

      span {
        white-space: nowrap;

        @include tablet {
          white-space: normal;
        }

        @include mobile {
          white-space: nowrap;
        }
      }
    }
  }

  .items-list__checked-item {
    &:before {
      left: 9px;

      @include mobile {
        left: -33px;
      }
    }
  }

  .approach {
    .card {
      width: 540px;
      padding-right: 24px;

      @include tablet {
        width: 100%;
      }

      @include mobile {
        min-height: 291px;
        align-items: center;
      }
    }

    .card--vertical {
      min-height: unset;

      @include mobile {
        min-height: 291px;
      }

      .button {
        min-width: 330px;

      @include tablet {
        min-width: 230px;
      }
      }
    }

    .subtitle {
      max-width: 840px;

      @include mobile {
        margin-bottom: 27px;
      }
    }

    .card__title {
      min-height: unset;
      max-width: 360px;
    }

    .card__text {
      @include mobile {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .prices {
    .card__title {
      margin-bottom: 20px;
    }

    .card__list {
      margin-bottom: 30px;

      li {
        margin-bottom: 17px;
      }
    }

    .card--listed>p img {
      @include tablet {
        height: 22px;
      }

      @include mobile {
        height: 26px;
      }
    }
  }

  .additional {
    .title {
      margin-bottom: 17px;
    }

    .subtitle {
      @include mobile {
        margin-bottom: 20px;
      }
    }

    .items-list__checked-list {
      @include mobile {
        padding-left: 33px;
      }
    }

    .items-list__checked-item {
      margin-bottom: 18px;

      @include mobile {
        padding-left: 0;
      }
    }

    .items-list__item {
      padding-right: 0;
      margin-bottom: 35px;

      @include mobile {
        padding-bottom: 9px;
        margin-bottom: 25px;
      }

      h3 {
        @include mobile {
          line-height: 32px;
        }
      }
    }

    .items-list__column:first-child {
      margin-right: 83px;

      @include tablet {
        margin-right: 10px;
        width: 30%;
      }

      @include mobile {
        width: 100%;
      }
    }
  }

  .audit {
    padding-bottom: 75px;

    @include mobile {
      padding-bottom: 20px;
    }

    .subtitle {
      max-width: 830px;

      @include mobile {
        margin-bottom: 27px;
      }
    }

    .card--vertical {
      padding: 35px 25px 45px;
      min-height: unset;
    }

    .card__icon {
      margin-bottom: 18px;
    }

    .card__title {
      max-width: unset;
      min-height: unset;
    }

    .card__text {
      letter-spacing: .02em;
      text-align: center;
    }

    .card--callback {
      padding: 60px 90px;
      align-items: center;
      background-image: unset;

      @include mobile {
        padding: 60px 20px;
        text-align: center;
      }

      .card__title {
        @include mobile {
          text-align: center;
        }
      }
    }

    .button {
      min-width: 330px;

      @include mobile {
        min-width: 230px;
      }
    }

    .flex-list__item:nth-of-type(n+4) {
      margin-top: 22px;
    }
  }

  .working-conditions {

    @include mobile {
      padding-top: 10px;
    }

    .subtitle {
      max-width: 600px;

      @include mobile {
        margin-bottom: 27px;
      }
    }

    .card {
      width: 540px;
      padding-right: 42px;

      @include mobile {
        min-height: 267px;
      }
    }

    .card--horizontal .card__title {
      display: none;
    }

    .card--vertical {
      padding: 35px 25px 45px;
      min-height: unset;

      @include mobile {
        min-height: 267px;
      }
    }

    .card__request-title {
      margin-bottom: 85px;
    }

    .card--callback {
      padding: 45px 60px;
      align-items: center;
      background-image: unset;

      @include mobile {
        padding: 60px 20px;
        text-align: center;
      }

      .card__text {
        margin-bottom: 33px;
        text-align: center;
      }

      .button {
        min-width: 330px;

        @include tablet {
          min-width: 230px;
        }
      }
    }

    .card__title {
      max-width: unset;
      min-height: unset;
      font-weight: 700;

      @media (max-width: 1279px) {
        text-align: center;
      }
    }
  }

  .customization {
    .card--horizontal {
      min-height: 308px;
    }
  }

  .bid-manager {
    .subtitle {
      max-width: 900px;

      @include mobile {
        margin-bottom: 27px;
      }
    }

    .card {
      @include mobile {
        min-height: 309px;
      }
    }
  }

  .automatation--context {
    .title {
      margin-bottom: 0;
    }

    .tabs__controls {
      margin-left: 17px;
      margin-bottom: 20px;
    }

    .automatation__item {
      padding-bottom: 0;
    }

    .automatation__image {
      width: 41%;
      margin-top: -10px;

      @include mobile {
        width: 100%;
      }
    }
  }

  .items-list--trapezoid {
    background-image: url("../img/bg/trapezoid.svg");
    background-repeat: no-repeat;

    .items-list__item {
      border-bottom: none;
    }
  }

  .accordion__icon {
    @include mobile {
      top: 8px;
      transform: translate(0);
    }
  }

  .accordion__item p {
    font-size: 15px;
    line-height: 22px;
  }
}

.context-page--service .promo--context .card {
  min-height: auto;
}

.context-page .approach--no-title .card__title {
  display: none;
}

.context-page--service {
  .check-form--bottom {
    .form {
      flex-direction: column;
      align-items: center;
    }

    p {
      margin-top: 30px;
      text-align: center;
    }

    p,
    a {
      color: $color-white;
    }

    .button {
      background-color: $color-transparent;
      border: 1px solid $color-green;
      border-radius: 0;
      margin-top: 32px;
      width: 200px;
    }

    .form__wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -20px;

      @include mobile {
        flex-direction: column;
        width: 100%;
      }
    }

    .form__inner {
      width: calc((100% - 20px) / 2);
      margin-bottom: 20px;

      @include tablet {
        margin-right: 20px;
      }

      @include mobile {
        margin-right: 0;
        width: 100%;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      input {
        width: 100%;
      }
    }
  }
}

.context-page .promo--best .card__title {
  border-bottom: 2px solid $color-black;

  &:hover {
    border-bottom: 2px solid transparent;
    color: $color-green;
  }
}

.context-page .approach .card {
  padding-right: 15px;
}

