.purposes {
  &__slider {
    .flex-list__item {
      @media (min-width: 768px) and (max-width: 1019px) {
        margin-right: 6% !important;
        width: 47% !important;

        &:nth-child(2n) {
          margin-right: 0 !important;
        }

        &:nth-child(n + 3) {
          margin-top: 30px !important;
        }
      }
    }
  }
}
