.infographics {
  &__bubbles {
    display: flex;
    flex-direction: column;
  }

  &__arrow {
    align-self: center;

    svg {
      fill: none;
    }
  }

  &__bubbles-list {
    $bubble_width: 190px;

    @include reset-list;

    position: relative;
    display: flex;
    justify-content: space-between;
    padding-bottom: 64px;

    &::before {
      @include pseudo;

      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      width: calc(100% - #{$bubble_width});
      background-color: $color-green;
    }

    li {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      padding: 0 15px;
      width: $bubble_width;
      height: 155px;
      border-radius: 50%;
      font-family: $font-family-primary-medium;
      font-size: 24px;
      font-weight: 500;
      color: $color-black;
      text-align: center;
      box-shadow: inset 0 0 0 2px $color-green;
      cursor: pointer;
      transition: background $default-transition-settings,
        color $default-transition-settings;

      &:last-child {
        margin-right: 0;
      }

      &::before {
        @include pseudo;

        bottom: -60px;
        left: 50%;
        width: 1px;
        height: 60px;
        border: 1px dashed $color-green;
        transform: translateX(-50%);
      }

      &::after {
        @include pseudo;

        bottom: -68px;
        left: 50%;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid $color-green;
        background-color: $color-white;
        transform: translateX(-50%);
      }

      @include min-desktop {
        &:hover {
          background-color: $color-green;
          color: $color-white;
        }
      }
    }

    @include container {
      $bubble_width: 150px;

      &::before {
        width: calc(100% - #{$bubble_width});
      }

      li {
        margin-right: 10px;
        font-size: 18px;
        width: $bubble_width;
        height: 120px;
      }
    }
  }

  &__table {
    border-top: 1px solid $color-very-light-grey;
    border-bottom: 1px solid $color-very-light-grey;
  }

  &__row {
    display: flex;
    border-bottom: 1px solid $color-very-light-grey;

    &:last-child {
      border-bottom: none;
    }

    & > div {
      border-right: 1px solid $color-very-light-grey;

      &:last-child {
        border-right: none;
      }
    }

    .infographics__percent {
      border-color: $color-green;
    }
  }

  &__description {
    display: flex;
    justify-content: space-between;
    padding: 30px 50px 30px 0;
    width: 51%;

    h3 {
      align-self: flex-start;
      margin: 0;
      margin-right: 40px;
      font-family: $font-family-primary-medium;
      font-size: 18px;
      font-weight: 500;
      color: $color-black;
    }

    @include container {
      padding: 30px 30px 30px 0;
      width: 45%;
    }

    @include tablet {
      h3 {
        margin-right: 20px;
        font-size: 16px;
      }
    }
  }

  &__percent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20.54%;
    font-family: $font-family-primary-bold;
    font-weight: 700;
    font-size: 36px;
    color: $color-black;
  }

  &__right-col {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 25px 25px 40px;

    @include tablet {
      padding: 20px;
    }
  }

  &__list {
    @include reset-list;

    display: flex;
    flex-direction: column;

    li {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-bottom: 15px;
      text-align: right;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        order: -1;
        font-family: $font-family-primary-medium;
        font-weight: 500;
        font-size: 18px;
        color: $color-black;
      }

      svg {
        margin-right: 20px;
        transform: rotate(180deg);
        fill: $color-green;
      }
    }

    @include tablet {
      li {
        span {
          font-size: 14px;
        }
      }
    }
  }

  &__coins {
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
  }

  &--pie {
    position: relative;

    .infographics__row {
      justify-content: space-between;
      margin-bottom: 30px;
      border: none;

      &:last-child {
        margin-bottom: 0;
      }

      & > div {
        border-right: 2px solid rgba($color: #000000, $alpha: 0.2);
      }
    }

    .infographics__section {
      padding: 23px 10px 23px 18px;
      max-width: 310px;
      min-width: 310px;
      border: 2px solid rgba($color: #000000, $alpha: 0.2);
      border-radius: 10px;

      &-items {
        @include reset-list;

        & > li {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          p {
            margin: 0;
            font-family: $font-family-primary-medium;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            color: $color-black;

            @include tablet {
              font-size: 16px;
            }
          }

          svg {
            margin-right: 18px;
          }
        }
      }

      @include tablet {
        min-width: 250px;
        max-width: 250px;
      }
    }

    .infographics__circle {
      $chart-width: 328px;

      position: absolute;
      left: 50%;
      top: 49.5%;
      display: flex;
      flex-wrap: wrap;
      width: $chart-width;
      height: $chart-width;
      border-radius: 50%;
      transform: translate(-50%, -50%);

      .infographics__hole {
        position: absolute;
        left: 50%;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        width: 206px;
        height: 206px;
        border-radius: 50%;
        background-color: $color-default-white;
        font-family: $font-family-primary-bold;
        font-size: 30px;
        line-height: 35px;
        font-weight: 700;
        color: $color-green;
        text-align: center;
        transform: translate(-50%, -50%);

        @include container {
          width: 170px;
          height: 170px;
        }
      }

      .infographics__quarter {
        position: relative;
        width: 50%;
        font-family: $font-family-primary-medium;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: $color-black;
        cursor: pointer;
        transition: background $default-transition-settings, color $default-transition-settings;

        &::before {
          @include pseudo;

          z-index: -1;
          width: 117px;
          height: 35px;
          background-image: url("../img/svg/line.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        span {
          display: block;
          text-align: center;
        }

        &:nth-child(1) {
          background-color: #afafaf;
          border-top-left-radius: $chart-width / 2;

          &::before {
            transform: rotate(33deg) translateX(-47px) translateY(42px);
          }

          span {
            transform: rotate(-43deg) translateX(-42px) translateY(113%);
          }

          @include container {
            &::before {
              transform: rotate(33deg) translateX(10px) translateY(42px);
            }
          }

          @include tablet {
            span {
              transform: rotate(-48deg) translateX(-45px) translateY(61%);
            }
          }
        }

        &:nth-child(2) {
          background-color: #ececec;
          border-top-right-radius: $chart-width / 2;

          &::before {
            transform: rotate(0deg) translateX(110px) translateY(10px);
          }

          span {
            transform: rotate(38deg) translateX(36px) translateY(118%);
          }

          @include container {
            &::before {
              transform: rotate(0deg) translateX(56px) translateY(39px);
            }
          }

          @include tablet {
            &::before {
              transform: rotate(0deg) translateX(25px) translateY(39px);
            }

            span {
              transform: rotate(44deg) translateX(36px) translateY(73%);
            }
          }
        }

        &:nth-child(3) {
          background-color: #c6c6c6;
          border-bottom-left-radius: $chart-width / 2;

          &::before {
            transform: rotate(0deg) translateX(-64px) translateY(112px);
          }

          span {
            transform: rotate(47deg) translateX(32px) translateY(178%);
          }

          @include container {
            &::before {
              transform: rotate(0deg) translateX(-10px) translateY(112px);
            }
          }

          @include tablet {
            &::before {
              transform: rotate(0deg) translateX(-12px) translateY(63px);
            }

            span {
              transform: rotate(47deg) translateX(32px) translateY(152%);
            }
          }
        }

        &:nth-child(4) {
          background-color: #d9d9d9;
          border-bottom-right-radius: $chart-width / 2;

          &::before {
            transform: rotate(34deg) translateX(153px) translateY(30px);
          }

          span {
            transform: rotate(-37deg) translateX(-43px) translateY(216%);
          }

          @include container {
            &::before {
              transform: rotate(34deg) translateX(112px) translateY(63px);
            }
          }

          @include tablet {
            &::before {
              transform: rotate(34deg) translateX(54px) translateY(42px);
            }

            span {
              transform: rotate(-37deg) translateX(-31px) translateY(185%);
            }
          }
        }

        &:hover {
          background-color: $color-green;
          color: $color-default-white;
        }

        @include tablet {
          font-size: 16px;
        }
      }

      @include container {
        width: 300px;
        height: 300px;
      }

      @include tablet {
        width: 250px;
        height: 250px;
      }
    }
  }
}
