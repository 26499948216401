.show-more {
  overflow-anchor: none;

  &__content {
    position: relative;
    max-height: 575px;
    min-height: 250px;
    overflow: hidden;
    transition: max-height $default-transition-duration cubic-bezier(0, 1, 0, 1);

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 96px;
      transition: opacity $default-transition-settings;
      background: linear-gradient(180deg, rgba($color-default-white, 0) 0%, rgba($color-default-white, 0.87) 68.75%, $color-default-white 100%);
    }

    @include mobile {
      max-height: 800px;
    }
  }

  .show-more__content.flex-list--4.flex-list--squares {
    max-height: calc((#{$container} - 90px) / 4);

    @include desktop {
      max-height: calc((#{$container--1280} - 90px) / 4);
    }

    @include container {
      max-height: calc((#{$container--1280} - 90px) / 4);
    }

    @include tablet {
      max-height: calc(((100vw - #{$padding-tablet}) - 90px) / 4);
    }
  }

  &--sm {
    .show-more__content {
      max-height: 51px;
    }

    .show-more__btn {
      margin-top: 20px;
    }
  }

  &--desktop {
    @include mobile {
      .show-more__content {
        max-height: 100% !important;
        overflow: unset;
        transition: unset;
      }

      .show-more__btn {
        display: none;
      }
    }
  }

  &--center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--no-gradient {
    .show-more__content {
      &::after {
        display: none;
      }
    }
  }

  &--mobile {
    .show-more__content {
      &::after {
        display: none;
      }
    }

    @include mobile {
      .show-more__content {
        max-height: 128px;
      }

      .show-more__btn {
        margin-top: 12px;
      }

      &--gradient .show-more__content::after {
        display: block;
      }
    }

    @include min-tablet {
      .btn-show-more {
        display: none;
      }

      .show-more__content {
        max-height: 9999px;
      }
    }
  }

  &--accordion {
    @include mobile {
      .show-more__content {
        max-height: 0;
      }

      .show-more__btn {
        position: relative;
        margin-top: 0;
        flex-shrink: 0;

        &::after {
          @include icon-chevron(9px, 2px);
          position: absolute;
          right: 0;
          top: 50%;
          content: "";
          transform: translate(-50%, calc(-2px - 50%)) rotate(-45deg) translateZ(0);
          transition: transform $default-transition-settings;
        }
      }

      &.show-more--active .show-more__btn::after {
        transform: translate(-50%, calc(2px - 50%)) rotate(-225deg) translateZ(0);
      }
    }
  }

  &__btn {
    margin-top: 30px;

    @include mobile {
      margin-top: 15px;
    }
  }

  &--active {
    .show-more__content::after {
      opacity: 0;
      z-index: -1;
      transition: opacity $default-transition-settings;
    }

    .show-more__content {
      max-height: 9999px !important;
      transition: max-height 0.6s ease-in;
    }

    .btn-show-more__icon {
      transform: rotate(45deg) translateZ(0);
    }

    &.show-more--table .show-more__content {
      padding-bottom: 30px;
      margin-bottom: -30px;
    }

    &.show-more--table .show-more__btn {
      transform: translateY(0) translateZ(0);
    }

    .show-more__btn {
      .btn-show-more__text:last-of-type:not(:first-of-type) {
        display: inline-block;
      }

      .btn-show-more__text:first-of-type:not(:last-of-type) {
        display: none;
      }
    }
  }
}


.btn-show-more {
  @include reset-btn;
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-family: $font-family-primary-medium;
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  text-decoration-line: underline;
  color: $color-green;
  transform: translateZ(0);

  transition: opacity $default-transition-settings;

  &--no-icon {
    font-weight: 700;

    .btn-show-more__icon {
      display: none;
    }
  }

  &__icon {
    display: flex;
    margin-right: 13px;
    color: $color-grey;
    transition: transform $default-transition-settings;

    svg {
      fill: none;
    }
  }

  &__text {
    @include first-letter-caps;

    display: inline-block;

    &:last-of-type:not(:first-of-type) {
      display: none;
    }
  }

  &--green-icon .btn-show-more__icon{
    color: $color-green;
  }

  &:hover {
    opacity: 0.7;
  }
}
