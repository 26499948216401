.problems--direct {
  @include mobile {
    padding-bottom: 23px;
  }

  .card {
    padding: 40px 30px;

    @include tablet {
      padding: 30px 15px;
    }
  }

  .card__title {
    max-width: none;
    min-height: 0;

    @include mobile {
      margin-bottom: 18px;
      margin-top: 4px;
    }
  }

  .subtitle {
    @include mobile {
      margin-bottom: 26px;
      max-width: 86%;
    }
  }

  .card__text {
    @include mobile {
      max-width: 95%;
      letter-spacing: 0;
    }
  }
}
