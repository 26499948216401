.collection-scheme {
  @media (max-width: 1280px) {
    display: none;
  }

  &__wrapper {
    position: relative;
    height: 800px;
    width: 1110px;

    background-image: url("../img/scheme-bg.png");
    background-repeat: no-repeat;
    background-position: center;
  }

  &__block {
    position: absolute;
    display: flex;
  }

  &__block--left-top {
    top: 58px;
    left: 14px;
    justify-content: space-between;
    height: 316px;
    flex-direction: column;
  }

  &__block--left-middle {
    top: 399px;
    left: 15px;
    height: 235px;
    justify-content: space-between;
    flex-direction: column;
  }

  &__block--left-bottom {
    top: 666px;
    left: 13px;
    flex-direction: column;
  }

  &__block--top {
    top: 28px;
    left: 286px;
    flex-direction: row;
    width: 534px;
    justify-content: space-between;
  }

  &__block--center {
    top: 352px;
    left: 444px;

    .collection-scheme__icon-wrapper {
      width: 226px;
      height: 110px;
    }
  }

  &__block--right {
    top: 288px;
    right: 11px;
    flex-direction: column;
    height: 238px;
    justify-content: space-between;
  }

  &__block--bottom {
    top: 690px;
    left: 477px;
    width: 337px;
    justify-content: space-between;
    flex-direction: row;
  }

  &__block--top-subcategory {
    top: 167px;
    left: 481px;
  }

  &__block--bottom-subcategory {
    left: 476px;
    top: 561px;
    justify-content: space-between;
    width: 337px;
  }

  &__block--left-bottom-subcategory {
    left: 223px;
    top: 666px;
    flex-direction: column;
  }

  &__block--left-top-subcategory {
    top: 181px;
    left: 221px;
    flex-direction: column;
  }

  &__block--left-middle-subcategory {
    left: 220px;
    top: 481px;
    flex-direction: column;
  }

  &__icon-wrapper {
    border: 1px solid #e2e2e2;
    width: 150px;
    height: 70px;
    display: flex;
    border-radius: 10px;
    cursor: pointer;

    img {
      margin: auto;
    }

    @include min-desktop {
      &:hover {
        box-shadow: $shadow-main;
        border-color: transparent;
      }
    }
  }
}
