.feedback {
  &__inner {
    .title {
      margin: 0 auto 20px;
      max-width: 558px;
    }
  }

  @include mobile {
    &__inner {
      .title {
        margin-left: -7px;
        margin-right: -7px;
        margin-bottom: 10px;

        max-width: none;
      }

      & > p {
        margin-bottom: -9px;
      }
    }
  }
}

.feedback--direct {
  @include mobile {
    padding-bottom: 20px;
  }

  .subtitle {
    margin-bottom: 17px;

    @include mobile {
      margin-bottom: -9px;
    }
  }

  .card--feedback .card__title {
    margin-top: -9px;

    @include mobile {
      margin-top: -2px;
    }
  }

  .card--feedback .card__text {
    line-height: 26px;
    max-width: 95%;

    @include mobile {
      margin-bottom: 24px;
    }
  }
}

.feedback--target {
  @include mobile {
    padding-bottom: 22px;
  }

  .subtitle {
    margin-bottom: 16px;

    @include mobile {
      margin-bottom: -6px;
    }
  }

  .card--feedback .card__title {
    margin-top: -10px;

    @include mobile {
      margin-top: 0;
    }
  }

  .card--feedback .card__text {
    margin-bottom: 35px;
  }
}
