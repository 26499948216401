.bots {
  padding-bottom: 50px;

  @include mobile {
    padding-bottom: 24px;
  }

  .flex-list {
    flex-direction: column;

    @include mobile {
      flex-direction: row;
    }
  }

  .subtitle {
    max-width: 810px;
    margin-bottom: 44px;

    @include mobile {
      margin-bottom: 26px;
    }
  }

  .slider-mobile__pagination {
    @include mobile {
      margin-top: 14px;
    }
  }
}

.bots__sublist {
  display: flex;
  justify-content: space-between;
  list-style: none;
  width: 100%;
  margin-bottom: 30px;
  padding: 0;

  @include mobile {
    flex-direction: column;
    margin-bottom: 0;
  }

  .card--small {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 255px;
    height: 50px;
    padding: 10px 20px;

    color: $color-dolphin;

    @media (max-width: 1280px) {
      width: 200px;
    }

    @include tablet {
      width: 170px;
    }

    @include mobile {
      width: 100%;
      margin-bottom: 15px;
    }

    .card__text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    svg,
    img {
      margin-bottom: 0;
      margin-right: 10px;
    }

    &:hover {
      color: $color-black;

      .card__text {
        color: $color-green;
      }
    }
  }
}
