.registration {
  .card, .card__title {
    text-align: left;
    align-items: flex-start;
  }

  .card svg {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .card__title {
    @include tablet {
      max-width: 235px;
    }
  }


  .mobile-break {
    display: none;

    @include mobile {
      display: inline;
    }
  }

  .card__text {
    color: $color-black;
    margin-bottom: 12px;
    font-size: 17px;
  }

  .items-list__checked-list {
    margin-bottom: 30px;
  }

  .button {
    min-width: 320px;

    @include tablet {
      min-width: 280px;
    }

    @include mobile {
      align-self: center;
    }
  }

  .card {
    padding: 40px 55px;

    @include tablet {
      padding: 40px 10px;
      width: 100%;
      height: 100%;
    }
  }

  .flex-list__item:first-child {
    @include tablet {
      width: 100%;
      margin: 10px 0;
      // margin-bottom: 20px;
    }

    .button {
      background-color: $color-transparent;
      color: $color-green;
      margin-top: auto;
      margin-bottom: 0;

      &:hover {
        color: $color-white !important;
      }

      &::after,
      &::before {
        background-color: $color-green !important;
      }
    }

    .card {
      @include tablet {
        width: 100%;
      }
    }
  }

  .flex-list__item:last-child {
    @include tablet {
      margin: 10px 0;
      width: 100%;
    }

    .card {
      position: relative;
      border: 2px solid $color-green;

      &::before {
        content: "";
        position: absolute;
        width: 42px;
        height: 42px;
        right: 15px;
        top: 15px;
        border-radius: 50%;
        background-color: $color-green;
      }

      &::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 12px;
        right: 28px;
        top: 31px;
        mask: url("../img/sprite/icon-tick.svg");
        background-color: $color-white;
      }

      .title {
        font-weight: 700;
      }
    }
  }

  .flex-list {
    @include mobile {
      flex-direction: column;
    }
  }
}
